import React, { useEffect, useState } from "react";
import { Col, Flex } from "antd";
import SubMenu from "../../Components/SubMenu";
import { capitalizeAndFormat } from "../../helpers/utils";
import axiosInstance from "../../helpers/axios";
import Apis from "../../helpers/Apis";
import withRouter from "../../Common/withRouter";
import ContentTable from "./ContentTable";
import AddContent from "./AddContent";
import AppInfo from "../../Components/AppInfo";
import { useDispatch } from "react-redux";
import { setActiveTab } from "../../Redux/slices/activeTab.reducer";

const Content = () => {
  const path = location.pathname.split("/");
  const [tablesList, setTablesList] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const dispatch = useDispatch();

  const init = async () => {
    const resp1 = await axiosInstance.get(Apis.GET_ALL_SCHEMAS({}));
    if (resp1.status === 200) {
      setTablesList(resp1.data.payload.data);
      path[2] &&
        dispatch(
          setActiveTab({
            page: "content",
            tab: resp1.data.payload.data?.find((c) => c?._id === path[2])
              ?.collection_id,
            redirectionLink: `/content/${path[2]}`,
          }),
        );
    }
  };

  useEffect(() => {
    init();
  }, [path[2]]);

  return (
    <Flex className="w-full">
      <SubMenu
        isSubMenuLogo
        isSubMenuVersion
        items={tablesList
          ?.filter((item) => item?.visible)
          ?.map((t) => {
            return {
              ...t,
              link: "/content/" + t._id,
              name: capitalizeAndFormat(t.collection_name),
            };
          })}
        currentPage={path[1]}
      />
      {path.includes("add") || path.includes("edit") ? (
        <AddContent />
      ) : (
        <ContentTable
          tableType={"content"}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
        />
      )}
      <Col className="right-part">
        <AppInfo />
      </Col>
    </Flex>
  );
};

export default withRouter(Content);
