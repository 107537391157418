import i18n from "i18next";
import { initReactI18next } from "react-i18next";
// import enTranslations from "./locales/en.json";
// import nlTranslations from "./locales/nl.json";

/**
 * Initializes i18n with React, configuring translations for English and Dutch.
 */

i18n.use(initReactI18next).init({
  ns: ["system", "tenant"],
  defaultNS: "system",
  // lng: "nl-NL",
  // fallbackLng: "nl-NL",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
