import React, { useCallback, useEffect, useState } from "react";
import { Checkbox, Col, Modal, notification, Radio, Row, Spin } from "antd";
import LeftSideTitlePart from "../../Components/HeaderParts/LeftSideTitlePart";
import RightSIdeTitlePart from "../../Components/HeaderParts/RightSIdeTitlePart";
import IconsAll from "../../Components/IconsAll";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setActiveTab } from "../../Redux/slices/activeTab.reducer";
import axiosInstance from "../../helpers/axios";
import Apis from "../../helpers/Apis";
import withRouter from "../../Common/withRouter";
import { BsCheckLg } from "react-icons/bs";
import { AiOutlineClose } from "react-icons/ai";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { capitalizeAndFormat } from "../../helpers/utils";
import SelectTableColumns from "../../Components/SelectTableColumns";
import { flatten } from "flat";
import { TableStriped } from "../../Common/CommonUiStyles";
import { usePagination } from "../../helpers/custom-hooks/usePagination";
import { debounce } from "lodash";
import { LoadingOutlined } from "@ant-design/icons";
import NoData from "../../Components/NoData";
import { PiSmileySad } from "react-icons/pi";

dayjs.extend(utc);

const ContentTable = ({
  router,
  item,
  tableType,
  tableId,
  setShowDrawer,
  setRelationalData,
  selectedRows,
  setSelectedRows,
}) => {
  const { t } = useTranslation();
  const path = location.pathname.split("/");
  const activeTab = useSelector((state) => state.activeTab[path[1]]);
  const dispatch = useDispatch();
  const [contentData, setContentData] = useState([]);
  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [collectionData, setCollectionData] = useState(null);
  const [presets, setPresets] = useState(null);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [selectOpen, setSelectOpen] = useState(false);
  const [columnWidths, setColumnWidths] = useState({});
  const currentLng = useSelector((state) => state?.user?.userProfile?.language);
  const { pagination, updatePagination } = usePagination(
    1,
    10,
    "createdAt",
    true,
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setSelectedColumns(presets?.layout_query?.tabular?.fields || []);
  }, [presets]);

  useEffect(() => {
    searchTerm && searchTerm !== "" && setSearchTerm("");
    init(true, "");
  }, [path[2]]);

  const handleResizeStart = (key) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    const startX = e.clientX;
    const startWidth = columnWidths[key] || 150;

    const handleMouseMove = (moveEvent) => {
      setColumnWidths((prev) => ({
        ...prev,
        [key]: Math.max(startWidth + (moveEvent.clientX - startX), 50),
      }));
    };

    const handleMouseUp = () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  const handleDeleteClick = async () => {
    setOpen(false);
    try {
      if (path[2]) {
        const resp = await axiosInstance.put(Apis.DELETE_CONTENT(path[2]), {
          deleteContent: selectedRows,
        });
        if (resp.status === 200) {
          setSelectedRows([]);
          notification.success({ message: t("delete_content_success") });
          init(true);
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const init = async (
    getCollectionData = false,
    search = "",
    page = pagination.currentPage,
    limit = pagination.pageSize,
  ) => {
    if (path[2] || tableId) {
      setLoading(true);
      try {
        let resp2;
        if (getCollectionData) {
          const resp1 = await axiosInstance.get(
            Apis.GET_A_SCHEMA(tableId || path[2], true),
          );
          if (resp1.status === 200) setCollectionData(resp1.data.payload.data);
          resp2 = await axiosInstance.get(
            Apis.GET_PRESET(
              tableType === "content" ? path[2] : resp1.data.payload.data?._id,
            ),
          );
          if (resp2.status === 200) {
            setPresets(
              resp2.data.payload.data ? resp2.data.payload.data[0] : [],
            );
          }
        }

        const resp3 = await axiosInstance.post(
          Apis.GET_CONTENT(tableId || path[2], search, {
            page,
            limit,
          }),
          {
            columns: getCollectionData
              ? resp2.data.payload.data[0]?.layout_query?.tabular?.fields
              : selectedColumns,
          },
        );
        if (resp3.status === 200) {
          setContentData(resp3.data.payload.data);
          updatePagination(
            "totalItems",
            resp3.data?.payload?.meta?.total_found || 0,
          );
          updatePagination("currentPage", page);
          updatePagination("pageSize", limit);
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const debouncedInit = useCallback(
    debounce((search, page) => init(true, search, page), 1000),
    [path[2]],
  );

  const generateColumns = () => {
    if (!collectionData?.fields?.length) return [];

    let cols = collectionData.fields.map((f) => {
      return {
        title: (
          <div
            className="resizable-header"
            style={{ width: columnWidths[f.path] }}
          >
            {f?.meta?.translations?.find((t) => t?.language === currentLng)
              ?.translation || f.field}
            <div
              role="draggable"
              className="resize-handle"
              onMouseDown={handleResizeStart(f.path)}
              /* onDoubleClick={() => {
                  setColumnWidths((prev) => ({
                    ...prev,
                    [f.path]: "auto",
                  }));
                }} */
            ></div>
          </div>
        ),
        dataIndex: f?.path.replaceAll(".", "_") + f?._id,
        key: f.path,
        width: columnWidths[f.path],
        /* onHeaderCell: (column) => ({
            width: column.width,
            onResize: handleResize(index),
          }), */
        render: (record, rowData) => {
          let content;
          if (
            f?.meta?.interface === "dropdown_multiple" ||
            f?.meta?.interface === "dropdown"
          ) {
            const choices = f?.meta?.options?.choices || [];
            content = Array.isArray(record)
              ? record
                  .map((item) => choices?.find((c) => c?.value === item)?.label)
                  .join(",")
              : choices?.find((c) => c?.value === record)?.label;
          } else if (typeof record === "boolean") {
            content = record ? (
              <BsCheckLg size={24} />
            ) : (
              <AiOutlineClose size={24} />
            );
          } else if (f?.meta?.interface === "datetime") {
            content = record ? dayjs.utc(record).format() : "";
          } else if (f?.meta?.interface === "wysiwyg") {
            content = <div dangerouslySetInnerHTML={{ __html: record }} />;
          } else if (Array.isArray(record) && record.length > 0) {
            if (typeof record[0] === "object") {
              content = record?.map((r) => r?._id).join(",");
            } else {
              content = record?.join(",");
            }
          } else {
            content = record;
          }

          return (
            <div
              onClick={() => {
                if (tableType === "content") {
                  router.navigate(
                    `${activeTab?.redirectionLink}/edit/${rowData?._id}`,
                  );
                } else {
                  handleRowSelect(
                    !selectedRows?.includes(rowData?._id),
                    rowData,
                  );
                }
              }}
            >
              {content}
            </div>
          );
        },
      };
    });

    // if (tableType === "content") {
    cols = cols.filter((col) => {
      return presets?.layout_query?.tabular?.fields?.some((field) => {
        return field.value === col.key;
      });
    });
    // }

    cols.unshift({
      title: (
        <div
          className="resizable-header"
          style={{ width: columnWidths["select"] }}
        >
          Select
          <div
            className="resize-handle"
            onMouseDown={handleResizeStart("select")}
            /* onDoubleClick={() => {
              setColumnWidths((prev) => ({
                ...prev,
                select: "auto",
              }));
            }} */
          ></div>
        </div>
      ),
      dataIndex: "select",
      key: "select",
      width: columnWidths["select"],
      render: (text, record) =>
        item?.meta?.interface === "list-m2o" ? (
          <Radio
            onChange={(e) => handleRowSelect(e.target.checked, record)}
            checked={selectedRows?.includes(record?._id)}
          />
        ) : (
          <Checkbox
            onChange={(e) => handleRowSelect(e.target.checked, record)}
            checked={selectedRows?.includes(record?._id)}
          />
        ),
    });
    return cols;
  };
  const columns = generateColumns(collectionData?.fields);

  const handleRowSelect = (checked, record) => {
    if (item?.meta?.interface === "list-m2o") {
      setSelectedRows([record._id]);
    } else {
      setSelectedRows((prevSelectedRows) => {
        if (checked) {
          return [...prevSelectedRows, record._id];
        } else {
          return prevSelectedRows.filter((id) => id !== record._id);
        }
      });
    }
  };

  const handleDataAdd = async () => {
    setRelationalData(selectedRows);
    setSelectedRows([]);
    setShowDrawer(false);
  };

  const handleColumnsAdd = async () => {
    try {
      const resp = await axiosInstance.put(
        Apis.UPDATE_PRESET(path[2]),
        selectedColumns,
      );
      if (resp.status === 200) {
        setSelectedColumns([]);
        init(true);
      }
    } catch (error) {
      console.log("error", error);
    }
    setSelectOpen(false);
  };

  return (
    <Row className="main-content-wrap">
      <Col className="px-8 xl:px-16 left-part" span={24}>
        {tableType === "content" ? (
          <div className="flex flex-wrap md:items-center md:justify-between mt-9 mb-11 | drawer-header-style">
            <LeftSideTitlePart
              pageTitle={
                capitalizeAndFormat(collectionData?.collection_name) || ""
              }
              pageSubTitle={t("content")}
              pageIcon={true}
              pageInnerIcon={<IconsAll.PaginasIcon />}
              backButton={false}
            />
            <RightSIdeTitlePart
              isFilterShow={true}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              searchTermPlaceholder={t("search_in_collection")}
              addButton={true}
              addDisabled={!path[2]}
              deleteButton={selectedRows?.length > 0}
              deleteDisabled={!selectedRows?.length}
              handleDeleteClick={() => setOpen(true)}
              handleAddClick={() => {
                router.navigate(`/content/${path[2]}/add`);
                dispatch(
                  setActiveTab({
                    page: "content",
                    tab: path[2],
                    redirectionLink: `/content/${path[2]}/add`,
                  }),
                );
              }}
              debouncedInit={debouncedInit}
            />
          </div>
        ) : (
          <div className="flex flex-wrap md:items-center md:justify-between mt-9 mb-11 | drawer-header-style">
            <LeftSideTitlePart
              pageTitle={collectionData?.collection_id || ""}
              pageSubTitle={t("content")}
              pageIcon={true}
              pageInnerIcon={<IconsAll.PaginasIcon />}
              backButton={false}
            />
            <RightSIdeTitlePart
              isFilterShow={true}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              showButton={true}
              showDisabled={!selectedRows?.length}
              handleShowClick={handleDataAdd}
              debouncedInit={debouncedInit}
            />
          </div>
        )}
        <section className="mt-9">
          <div className="relative">
            <TableStriped
              columns={columns}
              dataSource={contentData?.map((c) => flatten(c, { safe: true }))}
              rowKey={(record) => record?._id}
              locale={{
                emptyText: (
                  <NoData
                    icon={<PiSmileySad size={44} />}
                    title={t("no_data_found")}
                  />
                ),
              }}
              pagination={{
                pageSizeOptions: [10, 25, 50],
                current: pagination.currentPage,
                pageSize: pagination.pageSize,
                total: pagination.totalItems,
                position: ["bottomRight"],
                showSizeChanger: true,
                locale: { items_per_page: "" },
                onChange: async (page, size) => {
                  page !== pagination.currentPage &&
                    (await init(true, searchTerm, page, size));
                },
                onShowSizeChange: async (page, size) => {
                  await init(true, searchTerm, page, size);
                },
              }}
              loading={{
                spinning: loading,
                indicator: (
                  <Spin
                    indicator={
                      <LoadingOutlined
                        style={{ fontSize: 36, color: "#1890ff" }}
                        spin
                      />
                    }
                  />
                ),
              }}
            />
            {contentData?.length > 0 && tableType === "content" && (
              <SelectTableColumns
                selectedColumns={selectedColumns}
                setSelectedColumns={setSelectedColumns}
                columnOptions={collectionData?.fields}
                handleColumnsAdd={handleColumnsAdd}
                selectOpen={selectOpen}
                setSelectOpen={setSelectOpen}
              />
            )}
          </div>
        </section>
      </Col>
      <Modal
        open={open}
        title={t("delete_content")}
        okText={t("delete_content")}
        onOk={handleDeleteClick}
        cancelText={t("cancel")}
        onCancel={() => setOpen(false)}
        className="modal-delete"
        footer={(_, { OkBtn, CancelBtn }) => (
          <>
            <CancelBtn />
            <OkBtn />
          </>
        )}
      >
        <p className="my-6">{t("delete_content_confirm")}</p>
      </Modal>
    </Row>
  );
};

export default withRouter(ContentTable);
