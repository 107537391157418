import { Button, Flex, notification, Select, Typography } from "antd";
import React, { useEffect, useRef } from "react";
import LeftSideTitlePart from "../../HeaderParts/LeftSideTitlePart";
import IconsAll from "../../IconsAll";
import RightSIdeTitlePart from "../../HeaderParts/RightSIdeTitlePart";
import styled from "styled-components";
import "cropperjs/dist/cropper.css";
import Cropper from "cropperjs";
import axiosInstance from "../../../helpers/axios";
import Apis from "../../../helpers/Apis";
import { getDisplayImage } from "../../../helpers/utils";
import { BiRotateRight } from "react-icons/bi";
import { LuFlipHorizontal2, LuFlipVertical2 } from "react-icons/lu";
import { LiaUndoAltSolid } from "react-icons/lia";
import { useTranslation } from "react-i18next";

const TItleIconStyle = styled(Typography)`
  min-width: 56px !important;
  width: 56px !important;
  height: 56px;
  border-radius: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const EditImage = ({
  setIsImageEditOpen,
  setFileDetails,
  fileDetails,
  assignImageUrl,
  isDrawer,
}) => {
  const imageElement = useRef(null);
  const cropperInstance = useRef(null);
  const { Option } = Select;
  const { t } = useTranslation();

  useEffect(() => {
    if (imageElement.current && !cropperInstance.current) {
      cropperInstance.current = new Cropper(imageElement.current, {
        viewMode: 1,
        movable: true,
        rotatable: true,
        scalable: true,
        zoomable: true,
        autoCropArea: 0.5,
        aspectRatio: 16 / 9,
      });
    }

    return () => {
      if (cropperInstance.current) {
        cropperInstance.current.destroy();
        cropperInstance.current = null;
      }
    };
  }, [fileDetails?.imageUrl]);

  const getCroppedImage = () => {
    return new Promise((resolve, reject) => {
      if (cropperInstance.current) {
        const canvas = cropperInstance.current.getCroppedCanvas();
        canvas.toBlob(
          (blob) => {
            if (blob) {
              resolve(blob);
            } else {
              reject(new Error("Blob conversion failed."));
            }
          },
          fileDetails?.type,
          1,
        );
      } else {
        reject(new Error("No cropper instance available."));
      }
    });
  };

  const rotateImage = (degree) => {
    if (cropperInstance.current) {
      const currentRotation = cropperInstance.current.getData().rotate || 0;
      cropperInstance.current.rotateTo(currentRotation + degree);
    }
  };

  const flipImage = (isHorizontal = false) => {
    if (cropperInstance.current) {
      const currentImageData = cropperInstance.current.getImageData();
      if (isHorizontal) {
        cropperInstance.current.scaleX((currentImageData.scaleX || 1) * -1);
      } else {
        cropperInstance.current.scaleY((currentImageData.scaleY || 1) * -1);
      }
    }
  };

  const handleImage = async () => {
    try {
      const formData = new FormData();
      const croppedBlob = await getCroppedImage();
      formData.append("file", croppedBlob, fileDetails?.filename_disk);

      const resp = await axiosInstance.put(
        Apis.UPDATE_FILE(fileDetails?._id),
        formData,
      );
      if (resp.status === 200) {
        notification.success({ message: t("image_update_success") });
        setIsImageEditOpen(false);
        let imageUrl = await getDisplayImage(fileDetails?._id);
        setFileDetails({ ...(resp.data?.payload?.data || {}), imageUrl });
        isDrawer && assignImageUrl();
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <div>
      <div className="flex flex-wrap md:items-center md:justify-between p-[24px]">
        <LeftSideTitlePart
          pageIcon
          pageInnerIcon={
            <TItleIconStyle>
              <IconsAll.ContentIcon strokeColor="#0085CA" />
            </TItleIconStyle>
          }
          pageTitle={t("editing_image")}
          pageSubTitle={t("changes_are_permanent")}
        />
        <RightSIdeTitlePart showButton handleShowClick={handleImage} />
      </div>
      <img
        src={fileDetails?.imageUrl}
        ref={imageElement}
        alt="Edit Image"
        className="min-h-[500px] max-h-[500px] !h-auto !w-full object-contain mx-auto"
      />
      <Flex className="flex-wrap justify-between gap-3 mt-6 px-[24px]">
        <Select
          defaultValue="16/9"
          style={{ width: 180, marginBottom: 20 }}
          onChange={(e) => cropperInstance.current.setAspectRatio(e)}
        >
          <Option value={1}>Square</Option>
          <Option value={16 / 9}>16:9</Option>
          <Option value={4 / 3}>4:3</Option>
          <Option value={3 / 2}>3:2</Option>
          <Option value={NaN}>Free</Option>
        </Select>
        <Flex className="flex-wrap gap-3">
          <Button shape="circle" size="large" onClick={() => rotateImage(-90)}>
            <BiRotateRight size={24} />
          </Button>
          <Button shape="circle" size="large" onClick={() => flipImage(true)}>
            <LuFlipHorizontal2 size={24} />
          </Button>
          <Button shape="circle" size="large" onClick={() => flipImage()}>
            <LuFlipVertical2 size={24} />
          </Button>
          <Button
            shape="circle"
            size="large"
            onClick={() => cropperInstance.current.reset()}
          >
            <LiaUndoAltSolid size={24} />
          </Button>
        </Flex>
      </Flex>
    </div>
  );
};

export default EditImage;
