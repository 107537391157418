import React, { useEffect, useState } from "react";
import { Flex, Spin, Table } from "antd";
import LeftSideTitlePart from "../../Components/HeaderParts/LeftSideTitlePart";
import RightSIdeTitlePart from "../../Components/HeaderParts/RightSIdeTitlePart";
import IconsAll from "../../Components/IconsAll";
import { styled } from "styled-components";
import { Link } from "react-router-dom";
import AppInfo from "../../Components/AppInfo";
import { useTranslation } from "react-i18next";
import { themes } from "../../config";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setActiveTab } from "../../Redux/slices/activeTab.reducer";
import Apis from "../../helpers/Apis";
import axiosInstance from "../../helpers/axios";
import AddRole from "./AddRole";
import { DrawerStyle } from "../../Common/CommonUiStyles";
import { PiSmileySad } from "react-icons/pi";
import NoData from "../../Components/NoData";
import { LoadingOutlined } from "@ant-design/icons";

const TableStripe = styled(Table)`
  .ant-table{
  background-color: ${() =>
    themes[useSelector((state) => state.theme.name)].components.Table.tableBg};
}
.ant-table {
  background-color: ${() =>
    themes[useSelector((state) => state.theme.name)].components.Table.tableBg};
  table{
    background-color:transparent;
    .ant-table-row{
      .ant-table-cell{
        &:first-child{
          border-radius: 8px 0 0 8px;
          border-left: 1px solid ${() =>
            themes[useSelector((state) => state.theme.name)].components.Table
              .borderColor} !important;
        }
        &:last-child{
          border-radius: 0 8px 8px 0;
          border-right: 1px solid ${() =>
            themes[useSelector((state) => state.theme.name)].components.Table
              .borderColor} !important;
        }
      }
    }
  }
  .gray-clr {
    color: ${() =>
      themes[useSelector((state) => state.theme.name)].token.grayClr};
  }
  
  .ant-table-cell,
  .ant-table-cell.ant-table-cell-fix-left{
    background-color: ${() =>
      themes[useSelector((state) => state.theme.name)].components.Table
        .sortedBg} !important;
      }
  }
  .ant-table-cell {
    border-bottom: 1px solid
        ${() =>
          themes[useSelector((state) => state.theme.name)].components.Table
            .borderColor} !important;
      border-top: 1px solid
        ${() =>
          themes[useSelector((state) => state.theme.name)].components.Table
            .borderColor} !important;
    &.ant-table-column-has-sorters {
      &:hover {
        background-color: ${() =>
          themes[useSelector((state) => state.theme.name)].components.Table
            .sortedBg} !important;
      }
    }
    &.ant-table-column-sort {
      background-color: ${() =>
        themes[useSelector((state) => state.theme.name)].components.Table
          .sortedBg};
    }
  }

  .imgLocationClr {
    color: ${() =>
      themes[useSelector((state) => state.theme.name)].components.Table
        .imgLocationClr};
    margin-left: 10px;
  }

}
`;

const Toegangscontrole = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const path = location.pathname.split("/");
  const [loading, setLoading] = useState(false);

  const columns = [
    {
      title: t("role"),
      dataIndex: "name",
      key: "name",
      render: (record, rowData) => (
        <Link
          to={`/settings/toegangscontrole/${rowData?._id}`}
          onClick={() => {
            dispatch(
              setActiveTab({
                page: "settings",
                tab: path[2],
                redirectionLink: "/settings/toegangscontrole",
              }),
            );
          }}
        >
          {record}
        </Link>
      ),
    },
    {
      title: t("tenant"),
      dataIndex: "tenant",
      key: "tenant",
      render: (record) => {
        return <>{record?.name}</>;
      },
    },
    // {
    //   title: "",
    //   dataIndex: "department",
    //   key: "department",
    // },
  ];

  const [roles, setRoles] = useState([]);

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    setLoading(true);
    try {
      const resp = await axiosInstance.get(Apis.GET_ALL_ROLES());
      if (resp.status === 200) setRoles(resp.data.payload.data.data);
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Flex className="w-full main-content-wrap">
      <div className="px-4 xl:px-16 w-full left-part">
        <div className="flex flex-wrap md:items-center md:justify-between mt-9 mb-11">
          <LeftSideTitlePart
            pageTitle={t("access_control")}
            pageSubTitle={t("setting")}
            pageIcon={true}
            pageInnerIcon={<IconsAll.ToegangsControleIcon />}
            backButton={false}
          />
          <RightSIdeTitlePart
            addButton={true}
            setIsDrawerOpen={setIsDrawerOpen}
          />
          <DrawerStyle
            width={870}
            open={isDrawerOpen}
            closeIcon={<IconsAll.CloseIconCustom />}
            onClose={() => {
              setIsDrawerOpen(false);
            }}
          >
            <AddRole setIsDrawerOpen={setIsDrawerOpen} init={init} />
          </DrawerStyle>
        </div>

        <section className="mt-9">
          <TableStripe
            columns={columns}
            dataSource={roles}
            pagination={false}
            rowKey={(record) => record?._id}
            scroll={{
              x: 300,
            }}
            locale={{
              emptyText: (
                <NoData
                  icon={<PiSmileySad size={44} />}
                  title={t("no_data_found")}
                />
              ),
            }}
            loading={{
              spinning: loading,
              indicator: (
                <Spin
                  indicator={
                    <LoadingOutlined
                      style={{ fontSize: 36, color: "#1890ff" }}
                      spin
                    />
                  }
                />
              ),
            }}
          />
        </section>
      </div>
      <AppInfo />
    </Flex>
  );
};

export default Toegangscontrole;
