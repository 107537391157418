import standaardImg from "./Assets/images/Icon/Standaard-theme.svg";
import noxStandaardImg from "./Assets/images/Icon/NOX-Standaard-theme.svg";
import noxMinimal from "./Assets/images/Icon/NOX-Minimal-theme.svg";
import noxClrMatch from "./Assets/images/Icon/NOX-Color-Match-theme.svg";
import darkStandaardImg from "./Assets/images/Icon/Dark-Standaard-theme.svg";
import darkNoxStandaardImg from "./Assets/images/Icon/Dark-NOX-Standaard-theme.svg";

const themes = {
  standard: {
    // name: "standard",
    name: "standard",
    key: "light_standard_theme",
    type: "light",
    img: standaardImg,
    companyLogoHeight: "16",
    direction: "ltr",
    token: {
      //colors Palette
      grayClr: "var(--dashboard-text-clr)",
      // Spacing and sizes
      controlHeight: 35,
      // Font Family
      fontFamily: "Lexend",
    },
    components: {
      Menu: {
        verticalSidebarWidth: 260,
      },
      Table: {
        tableBg: "#FFFFFF",
        borderColor: "rgba(225, 227, 230, 1)",
        sortedBg: "#F5F6F7",
        fontSize: "16px",
        fontWeightStrong: "500",
        imgLocationClr: "#394656",
        rowHoverBg: "rgba(0, 0, 0, .05)",
      },
      Pagination: {
        borderRadius: 8,
        itemBg: "#F5F6F7",
        colorPrimary: "#FFFFFF",
        colorPrimaryHover: "#ffffff",
        itemActiveBg: "#08182c",
      },
      Select: {
        borderRadius: 12,
        selectorBg: "#ffffff",
        colorIcon: "#E0364E",
        controlHeight: 56,
        colorBgContainer: "#ffffff",
      },
      Input: {
        colorBgContainer: "#FFF",
        colorBorder: "#E1E3E6",
        colorTextPlaceholder: "#88909A",
      },
      HeaderButtonsStyle: {
        // folder Button Style
        folderButtonBg: "#F5F6F7",
        folderButtonClr: "#0C2340",
        // Hover Style
        folderButtonHoverBg: "#D3E7FB",
        folderButtonHoverClr: "#4096ff",

        // Add Button Style
        addButtonBg: "#0C2340",
        addButtonClr: "#FFFFFF",
        // Hover Style
        addButtonHoverBg: "#D3E7FB",
        addButtonHoverClr: "#4096ff",

        // delete Button Style
        deleteButtonBg: "rgba(224, 54, 78, 0.08)",
        deleteButtonClr: "#E0364E",
        // Hover Style
        deleteButtonHoverBg: "#E0364E",
        deleteButtonHoverClr: "#fff",

        // Submit Button Style
        submitButtonBg: "#69CAA7",
        submitButtonClr: "#08182C",
        // Hover Style
        submitButtonHoverBg: "#D6FFF0",
        submitButtonHoverClr: "#08182C",

        // disabled Submit Button Style
        disabledSubmitButtonBg: "#F5F6F7",
        disabledSubmitButtonClr: "#E1E3E6",
        // Hover Style
        disabledSubmitButtonHoverBg: "#D6FFF0",
        disabledSubmitButtonHoverClr: "#08182C",

        // Archive Button Style
        archiveButtonBg: "#F5F6F7",
        archiveButtonClr: "#0C2340",
        // Hover Style
        archiveButtonHoverBg: "#D6FFF0",
        archiveButtonHoverClr: "#08182C",

        // back button Style
        backButtonBg: "rgba(225, 227, 230, 0.24);",
        backButtonBorder: "rgba(12, 35, 64, 0.24);",
        backButtonClr: "#08182C",
      },
    },
  },

  /* <============ NOX Standard Color Properties ============> */
  noxStandard: {
    name: "noxStandard",
    key: "light_nox_standard_theme",
    type: "light",
    img: noxStandaardImg,
    companyLogoHeight: "16",
    direction: "ltr",
    token: {
      //colors Palette
      grayClr: "var(--dashboard-text-clr)",
      // Spacing and sizes
      controlHeight: 35,
      // Font Family
      fontFamily: "Lexend",
    },
    components: {
      Menu: {
        verticalSidebarWidth: 260,
      },
      Table: {
        tableBg: "#FFFFFF",
        borderColor: "rgba(225, 227, 230, 1)",
        sortedBg: "#F5F6F7",
        fontSize: "16px",
        fontWeightStrong: "500",
        imgLocationClr: "#394656",
        rowHoverBg: "rgba(0, 0, 0, .05)",
      },
      Pagination: {
        borderRadius: 8,
        itemBg: "#F5F6F7",
        colorPrimary: "#FFFFFF",
        colorPrimaryHover: "#ffffff",
        itemActiveBg: "#08182c",
      },
      Select: {
        borderRadius: 12,
        selectorBg: "#ffffff",
        colorIcon: "#E0364E",
        controlHeight: 56,
        colorBgContainer: "#ffffff",
      },
      Input: {
        colorBgContainer: "#FFF",
        colorBorder: "#E1E3E6",
        colorTextPlaceholder: "#88909A",
      },
      HeaderButtonsStyle: {
        // folder Button Style
        folderButtonBg: "#F5F6F7",
        folderButtonClr: "#0C2340",
        // Hover Style
        folderButtonHoverBg: "#D3E7FB",
        folderButtonHoverClr: "#4096ff",

        // Add Button Style
        addButtonBg: "#0C2340",
        addButtonClr: "#FFFFFF",
        // Hover Style
        addButtonHoverBg: "#D3E7FB",
        addButtonHoverClr: "#4096ff",

        // delete Button Style
        deleteButtonBg: "rgba(224, 54, 78, 0.08)",
        deleteButtonClr: "#E0364E",
        // Hover Style
        deleteButtonHoverBg: "#E0364E",
        deleteButtonHoverClr: "#fff",

        // Submit Button Style
        submitButtonBg: "#69CAA7",
        submitButtonClr: "#08182C",
        // Hover Style
        submitButtonHoverBg: "#D6FFF0",
        submitButtonHoverClr: "#08182C",

        // disabled Submit Button Style
        disabledSubmitButtonBg: "#F5F6F7",
        disabledSubmitButtonClr: "#E1E3E6",
        // Hover Style
        disabledSubmitButtonHoverBg: "#D6FFF0",
        disabledSubmitButtonHoverClr: "#08182C",

        // Archive Button Style
        archiveButtonBg: "#F5F6F7",
        archiveButtonClr: "#0C2340",
        // Hover Style
        archiveButtonHoverBg: "#D6FFF0",
        archiveButtonHoverClr: "#08182C",

        // back button Style
        backButtonBg: "rgba(225, 227, 230, 0.24);",
        backButtonBorder: "rgba(12, 35, 64, 0.24);",
        backButtonClr: "#08182C",
      },
    },
  },

  /* <============ Minimal Color Properties ============> */
  minimal: {
    name: "minimal",
    key: "light_nox_minimal_theme",
    type: "light",
    img: noxMinimal,
    companyLogoHeight: "16",
    direction: "ltr",
    token: {
      //colors Palette
      grayClr: "var(--dashboard-text-clr)",
      // Spacing and sizes
      controlHeight: 35,
      // Font Family
      fontFamily: "Lexend",
    },
    components: {
      Menu: {
        verticalSidebarWidth: 260,
      },
      Table: {
        tableBg: "#FFFFFF",
        borderColor: "rgba(225, 227, 230, 1)",
        sortedBg: "#F5F6F7",
        fontSize: "16px",
        fontWeightStrong: "500",
        imgLocationClr: "#394656",
        rowHoverBg: "rgba(0, 0, 0, .05)",
      },
      Pagination: {
        borderRadius: 8,
        itemBg: "#F5F6F7",
        colorPrimary: "#FFFFFF",
        colorPrimaryHover: "#ffffff",
        itemActiveBg: "#08182c",
      },
      Select: {
        borderRadius: 12,
        selectorBg: "#ffffff",
        colorIcon: "#E0364E",
        controlHeight: 56,
        colorBgContainer: "#FFFFFF",
        colorBorder: "#E1E3E6",
        colorPrimary: "#88909A",
        colorPrimaryHover: "#88909A",
      },
      Input: {
        colorBgContainer: "#FFFFFF",
        colorBorder: "#E1E3E6",
        colorTextPlaceholder: "#88909A",
        activeBorderColor: "#88909A",
        hoverBorderColor: "#88909A",
      },
      HeaderButtonsStyle: {
        // folder Button Style
        folderButtonBg: "#F5F6F7",
        folderButtonClr: "#0C2340",
        // Hover Style
        folderButtonHoverBg: "#D3E7FB",
        folderButtonHoverClr: "#4096ff",

        // Add Button Style
        addButtonBg: "#0C2340",
        addButtonClr: "#FFFFFF",
        // Hover Style
        addButtonHoverBg: "#D3E7FB",
        addButtonHoverClr: "#4096ff",

        // delete Button Style
        deleteButtonBg: "rgba(224, 54, 78, 0.08)",
        deleteButtonClr: "#E0364E",
        // Hover Style
        deleteButtonHoverBg: "#E0364E",
        deleteButtonHoverClr: "#fff",

        // Submit Button Style
        submitButtonBg: "#69CAA7",
        submitButtonClr: "#08182C",
        // Hover Style
        submitButtonHoverBg: "#D6FFF0",
        submitButtonHoverClr: "#08182C",

        // disabled Submit Button Style
        disabledSubmitButtonBg: "#F5F6F7",
        disabledSubmitButtonClr: "#E1E3E6",
        // Hover Style
        disabledSubmitButtonHoverBg: "#D6FFF0",
        disabledSubmitButtonHoverClr: "#08182C",

        // Archive Button Style
        archiveButtonBg: "#F5F6F7",
        archiveButtonClr: "#0C2340",
        // Hover Style
        archiveButtonHoverBg: "#D6FFF0",
        archiveButtonHoverClr: "#08182C",

        // back button Style
        backButtonBg: "rgba(225, 227, 230, 0.24);",
        backButtonBorder: "rgba(12, 35, 64, 0.24);",
        backButtonClr: "#08182C",
      },
    },
  },

  /* <============ Color Match Color Properties ============> */
  colorMatch: {
    name: "colorMatch",
    key: "light_nox_color_match_theme",
    type: "light",
    img: noxClrMatch,
    companyLogoHeight: "16",
    direction: "ltr",
    token: {
      //colors Palette
      grayClr: "var(--dashboard-text-clr)",
      // Spacing and sizes
      controlHeight: 35,
      // Font Family
      fontFamily: "Lexend",
    },
    components: {
      Menu: {
        verticalSidebarWidth: 260,
      },
      Table: {
        tableBg: "#c4e5ea",
        borderColor: "rgba(0, 0, 0, .05)",
        sortedBg: "#c4e5ea",
        fontSize: "16px",
        fontWeightStrong: "500",
        imgLocationClr: "#394656",
        rowHoverBg: "rgba(1, 1, 1, .05)",
      },
      Pagination: {
        borderRadius: 8,
        itemBg: "#F5F6F7",
        colorPrimary: "#FFFFFF",
        colorPrimaryHover: "#ffffff",
        itemActiveBg: "#08182c",
      },
      Select: {
        borderRadius: 12,
        selectorBg: "#ffffff",
        colorIcon: "#E0364E",
        controlHeight: 56,
        colorBgContainer: "#FFFFFF",
        colorBorder: "#E1E3E6",
        colorPrimary: "#88909A",
        colorPrimaryHover: "#88909A",
      },
      Input: {
        colorBgContainer: "#9DCED4",
        colorBorder: "#E1E3E6",
        colorTextPlaceholder: "#08182C",
        activeBorderColor: "#FFFFFF",
        hoverBorderColor: "#FFFFFF",
      },
      HeaderButtonsStyle: {
        // folder Button Style
        folderButtonBg: "#F5F6F7",
        folderButtonClr: "#0C2340",
        // Hover Style
        folderButtonHoverBg: "#D3E7FB",
        folderButtonHoverClr: "#4096ff",

        // Add Button Style
        addButtonBg: "#0C2340",
        addButtonClr: "#FFFFFF",
        // Hover Style
        addButtonHoverBg: "#D3E7FB",
        addButtonHoverClr: "#4096ff",

        // delete Button Style
        deleteButtonBg: "rgba(224, 54, 78, 0.08)",
        deleteButtonClr: "#E0364E",
        // Hover Style
        deleteButtonHoverBg: "#E0364E",
        deleteButtonHoverClr: "#fff",

        // Submit Button Style
        submitButtonBg: "#69CAA7",
        submitButtonClr: "#08182C",
        // Hover Style
        submitButtonHoverBg: "#D6FFF0",
        submitButtonHoverClr: "#08182C",

        // disabled Submit Button Style
        disabledSubmitButtonBg: "#F5F6F7",
        disabledSubmitButtonClr: "#E1E3E6",
        // Hover Style
        disabledSubmitButtonHoverBg: "#D6FFF0",
        disabledSubmitButtonHoverClr: "#08182C",

        // Archive Button Style
        archiveButtonBg: "#F5F6F7",
        archiveButtonClr: "#0C2340",
        // Hover Style
        archiveButtonHoverBg: "#D6FFF0",
        archiveButtonHoverClr: "#08182C",

        // back button Style
        backButtonBg: "rgba(225, 227, 230, 0.24);",
        backButtonBorder: "rgba(12, 35, 64, 0.24);",
        backButtonClr: "#08182C",
      },
    },
  },

  // Theme colors specific to dark theme
  darkStandard: {
    name: "darkStandard",
    key: "dark_light_standard_theme",
    type: "dark",
    img: darkStandaardImg,
    companyLogoHeight: "16",
    direction: "ltr",
    token: {
      grayClr: "var(--dashboard-text-clr)",
      // Spacing and sizes
      controlHeight: 35,
      // Font Family
      fontFamily: "Lexend",
    },
    components: {
      Menu: {
        verticalSidebarWidth: 260,
      },
      Table: {
        tableBg: "transparent",
        borderColor: "rgba(225, 227, 230, 0.25)",
        sortedBg: "#1a3052",
        fontSize: "16px",
        fontWeightStrong: "500",
        imgLocationClr: "#fff",
        rowHoverBg: "#436d97",
      },
      Pagination: {
        borderRadius: 8,
        itemBg: "#F5F6F7",
        colorPrimary: "#000",
        itemActiveBg: "#fefefe",
      },
      Select: {
        borderRadius: 12,
        selectorBg: "#0E2240",
        colorIcon: "#ffffff",
        colorPrimaryHover: "#ffffff",
        controlHeight: 56,
        colorBgElevated: "#0E2240",
        optionSelectedBg: "#1d4177",
        colorBorder: "rgba(225, 227, 230, 0.25)",
      },
      Input: {
        colorBgContainer: "#0E2240",
        colorBorder: "rgba(225, 227, 230, 0.25)",
        colorTextPlaceholder: "rgba(225, 227, 230, 1)",
        colorBgContainerDisabled: "#12233f",
        colorTextDisabled: "rgba(225,225,225,0.25)",
      },
      HeaderButtonsStyle: {
        // folder Button Style
        folderButtonBg: "#0085ca",
        folderButtonClr: "#0C2340",
        // Hover Style
        folderButtonHoverBg: "#D3E7FB",
        folderButtonHoverClr: "#4096ff",
        // add Button Style
        addButtonBg: "#0C2340",
        addButtonClr: "#FFFFFF",
        // Hover Style
        addButtonHoverBg: "#D3E7FB",
        addButtonHoverClr: "#4096ff",
        // delete Button Style
        deleteButtonBg: "rgba(224, 54, 78, 0.8)",
        deleteButtonClr: "#fff",
        // Hover Style
        deleteButtonHoverBg: "#E0364E",
        deleteButtonHoverClr: "#fff",
        // Submit Button Style
        submitButtonBg: "#69CAA7",
        submitButtonClr: "#08182C",
        // Hover Style
        submitButtonHoverBg: "#D6FFF0",
        submitButtonHoverClr: "#08182C",
        // disabled Submit Button Style
        disabledSubmitButtonBg: "#244a7a",
        disabledSubmitButtonClr: "#E1E3E6",
        // Hover Style
        disabledSubmitButtonHoverBg: "#D6FFF0",
        disabledSubmitButtonHoverClr: "#08182C",
        // Archive Button Style
        archiveButtonBg: "#69CAA7",
        archiveButtonClr: "#08182C",
        // Hover Style
        archiveButtonHoverBg: "#D6FFF0",
        archiveButtonHoverClr: "#08182C",
        // back button Style
        backButtonBg: "rgba(225, 227, 230, 0.24);",
        backButtonBorder: "rgba(12, 35, 64, 0.24);",
        backButtonClr: "#08182C",
      },
    },
  },

  darkNoxStandard: {
    name: "darkNoxStandard",
    key: "dark_light_nox_standard_theme",
    type: "dark",
    img: darkNoxStandaardImg,
    companyLogoHeight: "16",
    direction: "ltr",
    token: {
      grayClr: "var(--dashboard-text-clr)",
      // Spacing and sizes
      controlHeight: 35,
      // Font Family
      fontFamily: "Lexend",
    },
    components: {
      Menu: {
        verticalSidebarWidth: 260,
      },
      Table: {
        tableBg: "transparent",
        borderColor: "rgba(225, 227, 230, 0.25)",
        sortedBg: "#1a3052",
        fontSize: "16px",
        fontWeightStrong: "500",
        imgLocationClr: "#fff",
        rowHoverBg: "#436d97",
      },
      Pagination: {
        borderRadius: 8,
        itemBg: "#F5F6F7",
        colorPrimary: "#000",
        itemActiveBg: "#fefefe",
      },
      Select: {
        borderRadius: 12,
        selectorBg: "#0E2240",
        colorIcon: "#ffffff",
        colorPrimaryHover: "#ffffff",
        controlHeight: 56,
        colorBgElevated: "#0E2240",
        optionSelectedBg: "#1d4177",
        colorBorder: "rgba(225, 227, 230, 0.25)",
      },
      Input: {
        colorBgContainer: "#0E2240",
        colorBorder: "rgba(225, 227, 230, 0.25)",
        colorTextPlaceholder: "rgba(225, 227, 230, 1)",
        colorBgContainerDisabled: "#12233f",
        colorTextDisabled: "rgba(225,225,225,0.25)",
      },
      HeaderButtonsStyle: {
        // folder Button Style
        folderButtonBg: "#0085ca",
        folderButtonClr: "#0C2340",
        // Hover Style
        folderButtonHoverBg: "#D3E7FB",
        folderButtonHoverClr: "#4096ff",

        // add Button Style
        addButtonBg: "#0C2340",
        addButtonClr: "#FFFFFF",
        // Hover Style
        addButtonHoverBg: "#D3E7FB",
        addButtonHoverClr: "#4096ff",

        // delete Button Style
        deleteButtonBg: "rgba(224, 54, 78, 0.8)",
        deleteButtonClr: "#fff",
        // Hover Style
        deleteButtonHoverBg: "#E0364E",
        deleteButtonHoverClr: "#fff",

        // Submit Button Style
        submitButtonBg: "#69CAA7",
        submitButtonClr: "#08182C",
        // Hover Style
        submitButtonHoverBg: "#D6FFF0",
        submitButtonHoverClr: "#08182C",

        // disabled Submit Button Style
        disabledSubmitButtonBg: "#244a7a",
        disabledSubmitButtonClr: "#E1E3E6",
        // Hover Style
        disabledSubmitButtonHoverBg: "#D6FFF0",
        disabledSubmitButtonHoverClr: "#08182C",

        // Archive Button Style
        archiveButtonBg: "#69CAA7",
        archiveButtonClr: "#08182C",
        // Hover Style
        archiveButtonHoverBg: "#D6FFF0",
        archiveButtonHoverClr: "#08182C",

        // back button Style
        backButtonBg: "rgba(225, 227, 230, 0.24);",
        backButtonBorder: "rgba(12, 35, 64, 0.24);",
        backButtonClr: "#08182C",
      },
    },
  },
};

export { themes };
