import { DownOutlined } from "@ant-design/icons";
import { Button, Dropdown, Space, Tooltip } from "antd";
import React from "react";
import { IoIosInformationCircleOutline } from "react-icons/io";
import IconsAll from "../IconsAll";
import { UpdateInputStyle } from "../../Common/SidebarStyle";
import { useTranslation } from "react-i18next";

const PlaceholderWithTranslations = ({
  selectedTranslation,
  setSelectedTranslation,
  form,
  setIsTranslationDrawerOpen,
  translationsList,
}) => {
  const { t } = useTranslation();
  return (
    <UpdateInputStyle
      value={form.getFieldValue("placeholder") || ""}
      onChange={(e) => {
        !selectedTranslation &&
          form.setFieldValue("placeholder", e.target.value || "");
      }}
      placeholder={t("enter_a_placeholder")}
      disabled={selectedTranslation}
      addonAfter={
        selectedTranslation ? (
          <Button
            onClick={() => {
              form.setFieldValue("placeholder", "");
              setSelectedTranslation(null);
            }}
            className="delete-button"
          >
            <IconsAll.TrashIcon />
          </Button>
        ) : (
          <Dropdown
            className="custom-add-Translation-dropdown"
            trigger={"click"}
            menu={{
              items: [
                ...(translationsList?.map((tr) => {
                  return {
                    key: tr?._id,
                    label: (
                      <div
                        onClick={() => {
                          form.setFieldValue("placeholder", tr?.key);
                          setSelectedTranslation(tr?.key);
                        }}
                        className="custom-add-Translation-dropdown-list"
                      >
                        {tr?.key}
                        <Tooltip
                          title={tr?.translations?.map((tr, i) => {
                            return (
                              <div key={i}>
                                {tr?.language}: {tr?.value}
                              </div>
                            );
                          })}
                        >
                          <IoIosInformationCircleOutline />
                        </Tooltip>
                      </div>
                    ),
                  };
                }) || []),
                {
                  key: "add-button",
                  label: (
                    <div onClick={() => setIsTranslationDrawerOpen(true)}>
                      {t("new_custom_translation")}
                    </div>
                  ),
                },
              ],
            }}
          >
            <a onClick={(e) => e.preventDefault()}>
              <Space>
                <DownOutlined />
              </Space>
            </a>
          </Dropdown>
        )
      }
    />
  );
};

export default PlaceholderWithTranslations;
