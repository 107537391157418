import React from "react";
import { styled } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { setTheme } from "../Redux/slices/theme.reducer";
import { Form, Radio } from "antd";
import { useTranslation } from "react-i18next";
import { themes } from "../config";

const StandardDarkThemeStyle = styled(Form.Item)`
  .ant-radio-wrapper {
    padding: 8px 8px 16px;
    margin: 0;
    border-radius: 12px;
    color: var(--logo-bg);
    border: 1px solid hsla(var(--input-field-border-color), 0.25);
    background-color: #fff;
    position: relative;

    &.ant-radio-wrapper-checked {
      border: 0;
      outline: 2px solid orange;
      box-shadow: 0px 1px 6px 0px rgba(0, 133, 202, 0.24);
    }

    .ant-radio {
      position: absolute;
      bottom: 15px;
      left: 18px;

      + span {
        display: grid;
        gap: 16px;
        > span {
          padding-left: 40px;
        }
      }
    }
  }
`;

const StandardDarkTheme = () => {
  const currentTheme = useSelector((state) => state.theme.name);
  const dispatch = useDispatch();

  const onChange = (e) => {
    dispatch(setTheme(e.target.value));
  };
  const { t } = useTranslation();

  return (
    <StandardDarkThemeStyle
      label={t("standard_dark_theme")}
      className="font-bold"
    >
      <Radio.Group
        onChange={onChange}
        value={currentTheme}
        className="gap-x-5 gap-y-6 flex flex-wrap"
      >
        {Object.entries(themes)
          .filter(([, val]) => val.type === "dark")
          .map(([, val], ind) => {
            return (
              <Radio key={ind + 1} value={val.name}>
                <img src={val.img} alt="" />
                <span>{t(val.key)}</span>
              </Radio>
            );
          })}
      </Radio.Group>
    </StandardDarkThemeStyle>
  );
};

export default StandardDarkTheme;
