import React, { useEffect, useState } from "react";
import { Form, Row, notification, Flex } from "antd";
import GenerateUpdateFieldsForm from "../../Update-Schema-Fields/GenerateUpdateFieldsForm";
import RightSIdeTitlePart from "../../HeaderParts/RightSIdeTitlePart";
import IconsAll from "../../IconsAll";
import LeftSideTitlePart from "../../HeaderParts/LeftSideTitlePart";
import { flatten, unflatten } from "flat";
import axiosInstance from "../../../helpers/axios";
import Apis from "../../../helpers/Apis";
import EditConfig from "./editFieldsJson.json";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { FormStyling } from "../../../Common/CommonUiStyles";
import { capitalizeAndFormat } from "../../../helpers/utils";
dayjs.extend(utc);

const UpdateSchema = ({ activeItem, setIsDrawerOpen, dataSource }) => {
  const [form] = Form.useForm();
  const [tags, setTags] = useState(
    activeItem?.schema_definition?.default || [],
  );

  useEffect(() => {
    form.resetFields();
  }, [activeItem?._id]);

  const handleSubmit = async () => {
    try {
      const finalValues = unflatten({
        ...flatten(activeItem),
        ...(await form.getFieldsValue()),
      });
      const { _id, ...restOfSelectedField } = finalValues;
      delete restOfSelectedField.__v;
      let updatedDate = await form.getFieldValue("schema_definition.default");
      let final = {
        ...restOfSelectedField,
        schema_definition:
          restOfSelectedField?.meta?.interface === "datetime"
            ? {
                ...restOfSelectedField?.schema_definition,
                default: updatedDate ? updatedDate.toISOString() : null,
              }
            : restOfSelectedField?.meta?.interface === "tags"
              ? {
                  ...restOfSelectedField?.schema_definition,
                  default: tags,
                }
              : restOfSelectedField?.schema_definition,
      };

      const resp = await axiosInstance.put(Apis.UPDATE_A_FIELD(_id), final);
      if (resp.status === 200) {
        setIsDrawerOpen(false);
        notification.success({ message: t("field_update_success") });
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const getConfig = () => {
    switch (activeItem?.meta?.interface) {
      case "object":
        return EditConfig["object"];
      case "input":
        return EditConfig["input"][activeItem?.type];
      case "autocomplete":
        return EditConfig["autocomplete"];
      case "textarea":
        return EditConfig["textarea"];
      case "wysiwyg":
        return EditConfig["wysiwyg"];
      case "tags":
        return EditConfig["tags"];
      case "toggle":
        return EditConfig["toggle"];
      case "datetime":
        return EditConfig["datetime"];
      case "dropdown":
        return EditConfig["dropdown"];
      case "checkboxes":
        return EditConfig["checkboxes"];
      case "dropdown_multiple":
        return EditConfig["dropdown_multiple"];
      case "radio":
        return EditConfig["radio"];
      case "list-m2m":
        return EditConfig["list-m2m"];
      case "list-o2m":
        return EditConfig["list-o2m"];
      case "list-m2o":
        return EditConfig["list-m2o"];
      case "file":
        return EditConfig["file"];
      case "file-image":
        return EditConfig["file-image"];
      case "files":
        return EditConfig["files"];
      default:
        return {};
    }
  };

  return (
    <>
      <Flex wrap="wrap" align="center" justify="space-between">
        <LeftSideTitlePart
          pageTitle={
            capitalizeAndFormat(activeItem?.meta?.interface) +
            " " +
            capitalizeAndFormat(activeItem?.type) +
            " (" +
            capitalizeAndFormat(dataSource?.collection_name) +
            ")"
          }
          pageIcon={true}
          pageInnerIcon={<IconsAll.ContentIcon strokeColor={"#3586c7"} />}
        />
        <RightSIdeTitlePart showButton={true} handleShowClick={handleSubmit} />
      </Flex>
      <FormStyling form={form} className="mt-4" layout="vertical">
        <Row gutter={28}>
          {Object.entries(getConfig()).map(([key]) => (
            <GenerateUpdateFieldsForm
              key={key}
              activeItem={activeItem}
              category={key}
              form={form}
              tags={tags}
              setTags={setTags}
            />
          ))}
        </Row>
      </FormStyling>
    </>
  );
};

export default UpdateSchema;
