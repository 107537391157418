import React, { useEffect, useState } from "react";
import { BsInfoCircle } from "react-icons/bs";
import { Button, Modal } from "antd";
import Media from "../../../Pages/MediaLibrary/Media";
import FilesList from "./FilesList";
import CustomDragger from "./CustomDragger";

import { DrawerStyle, NotHasField } from "../../../Common/CommonUiStyles";
import { useTranslation } from "react-i18next";

const CustomFiles = ({ item, updateFormData, formData, isValidationError }) => {
  const [files, setFiles] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setFiles(formData?.[item?.path] ? formData?.[item?.path] : []);
  }, []);

  useEffect(() => {
    updateFormData({
      key: item?.path,
      value:
        item?.meta?.interface === "files"
          ? files?.map((f) => f?._id)
          : files?.length
            ? files[0]?._id
            : null,
    });
  }, [files]);

  const updateFilesList = (newFiles) => {
    setFiles((prev) => {
      return item?.meta?.interface === "files"
        ? [...prev, ...newFiles]
        : newFiles;
    });
  };

  const handleDelete = (img_id) => {
    setFiles((prev) => prev.filter((p) => p?._id !== img_id));
  };

  return (
    <div>
      {!files?.length ? (
        <NotHasField
          className={`items-center gap-3 font-normal rounded-xl ${isValidationError ? "!bg-[tomato]" : ""}`}
        >
          <BsInfoCircle size={20} />
          <p className="font-normal">{t("no_items")}</p>
        </NotHasField>
      ) : (
        files?.map((file) => (
          <FilesList
            key={file?._id}
            file={file}
            iFace={item?.meta?.interface}
            handleDelete={handleDelete}
            setFiles={setFiles}
          />
        ))
      )}
      <div className="mt-4">
        <Button
          type="primary"
          disabled={!!item.meta?.readonly}
          onClick={() => setIsModalOpen(true)}
          className="rounded-full border border-dark bg-btn-dark text-white px-5 py-3 font-medium h-auto mr-3"
        >
          {t("upload_file")}
        </Button>
        <Button
          type="primary"
          disabled={!!item.meta?.readonly}
          className="rounded-full bg-white border border-grey-900 text-black px-5 py-3 font-medium h-auto"
          onClick={() => setShowDrawer(true)}
        >
          {t("add_existing")}
        </Button>
      </div>
      <Modal
        title={t("add_file")}
        open={isModalOpen}
        onOk={() => setIsModalOpen(false)}
        okText={t("ok")}
        cancelText={t("cancel")}
        onCancel={() => setIsModalOpen(false)}
      >
        <CustomDragger
          iFace={item?.meta?.interface}
          setIsModalOpen={setIsModalOpen}
          updateFilesList={updateFilesList}
          folder={item?.meta?.folder}
        />
      </Modal>
      <DrawerStyle
        width={991}
        onClose={() => setShowDrawer(false)}
        open={showDrawer}
        className="table-in-drawer add-existing-file-drawer remove-padding"
      >
        <Media
          iFace={item?.meta?.interface}
          updateContentFilesList={updateFilesList}
          setShowDrawer={setShowDrawer}
        />
      </DrawerStyle>
    </div>
  );
};

export default CustomFiles;
