import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedFolderData: {},
  isMoveFolderModalOpen: false,
  folderActionData: {},
  destinationFolderData: {},
};

const mediaSlice = createSlice({
  name: "media",
  initialState,
  reducers: {
    setSelectedFolderData: (state, action) => {
      state.selectedFolderData = action.payload;
    },
    setIsMoveFolderModalOpen: (state, action) => {
      state.isMoveFolderModalOpen = action.payload;
    },
    setFolderActionData: (state, action) => {
      state.folderActionData = action.payload;
    },
    setDestinationFolderData: (state, action) => {
      state.destinationFolderData = action.payload;
    },
  },
});

export const {
  setSelectedFolderData,
  setIsMoveFolderModalOpen,
  setFolderActionData,
  setDestinationFolderData,
} = mediaSlice.actions;
export default mediaSlice.reducer;
