import React from "react";
import { styled } from "styled-components";
import { Tree } from "antd";
import { IoIosArrowForward } from "react-icons/io";
// treeData
const treeData = [
  {
    title: "globals",
    key: "0-0",
    children: [
      {
        title: "Inner Global",
        key: "0-0-0",
        disabled: false,
      },
    ],
  },
  {
    title: "fonts",
    key: "1-0",
    children: [
      {
        title: "Inner fonts",
        key: "1-0-0",
        disabled: false,
      },
    ],
  },
  {
    title: "navigation",
    key: "2-0",
    children: [
      {
        title: "Inner navigation",
        key: "2-0-0",
        disabled: false,
      },
    ],
  },
  {
    title: "header",
    key: "3-0",
    children: [
      {
        title: "Inner header",
        key: "3-0-0",
        disabled: false,
      },
    ],
  },
  {
    title: "form",
    key: "4-0",
    children: [
      {
        title: "Inner form",
        key: "4-0-0",
        disabled: false,
      },
    ],
  },
  {
    title: "sidebar",
    key: "5-0",
    children: [
      {
        title: "Inner sidebar",
        key: "5-0-0",
        disabled: false,
      },
    ],
  },
  {
    title: "public",
    key: "6-0",
    children: [
      {
        title: "Inner public",
        key: "6-0-0",
        disabled: false,
      },
    ],
  },
  {
    title: "popover",
    key: "7-0",
    children: [
      {
        title: "Inner popover",
        key: "7-0-0",
        disabled: false,
      },
    ],
  },
];

const TreeStyle = styled(Tree)`
  margin-top: 10px;
  border: 1px solid hsla(var(--input-field-border-color), 0.25);
  padding: 16px 24px;
  background-color: var(--input-field-bg);
`;

const LightThemeCustomization = () => {
  return (
    <TreeStyle
      treeData={treeData}
      switcherIcon={<IoIosArrowForward />}
    ></TreeStyle>
  );
};

export default LightThemeCustomization;
