import { useState } from "react";

export const usePagination = (
  initialPage = 1,
  initialPageSize = 25,
  initialSortKey = "createdAt",
  initialIsSortDesc = true,
) => {
  const [pagination, setPagination] = useState({
    currentPage: initialPage,
    pageSize: initialPageSize,
    totalItems: 0,
    sortKey: initialSortKey,
    isSortDesc: initialIsSortDesc,
  });

  const updatePagination = (key, value) => {
    setPagination((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  return {
    pagination,
    updatePagination,
  };
};
