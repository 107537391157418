import {
  Button,
  Checkbox,
  Col,
  ColorPicker,
  DatePicker,
  Form,
  Input,
  InputNumber,
  notification,
  Radio,
  Row,
  Select,
  Tag,
  TreeSelect,
} from "antd";
import React, { useEffect, useId, useState } from "react";
import {
  convertToTreeData,
  hasNewFieldFunc,
  removeNullAndUndefined,
  TableRow,
} from "../../helpers/utils";
import {
  DrawerStyle,
  FormStyling,
  InvertedCheckBox,
} from "../../Common/CommonUiStyles";
import { useParams } from "react-router-dom";
import axiosInstance from "../../helpers/axios";
import Apis from "../../helpers/Apis";
import TextArea from "antd/es/input/TextArea";
import ReactQuill from "react-quill";
import { BsInfoCircle, BsTags } from "react-icons/bs";
import { IoLanguage } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import { DndContext } from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { TableSort } from "../../Pages/Instellingen/DataModal";
import { AiOutlineClose } from "react-icons/ai";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import CreateChoices from "./CreateChoices";
import PlaceholderWithTranslations from "./PlaceholderWithTranslations";
import IconsAll from "../IconsAll";
import AddVertalingen from "../../Pages/Instellingen/AddVertalingen";

const convertToTreeFormat = (data) => {
  const treeMap = new Map();
  const tree = [];
  data?.forEach((item) => {
    const node = {
      title: item?.name,
      value: item?._id,
      children: [],
    };
    treeMap.set(item?._id, node);
  });
  data?.forEach((item) => {
    if (item?.parent) {
      if (treeMap.has(item?.parent)) {
        treeMap.get(item?.parent).children.push(treeMap.get(item?._id));
      }
    } else {
      tree.push(treeMap.get(item?._id));
    }
  });
  return tree;
};

const options = [
  "Undo",
  "Redo",
  "Bold",
  "Italic",
  "Underline",
  "Strikethrough",
  "Subscript",
  "Superscript",
  "Select Font",
  "Select Font Size",
  "Heading 1",
  "Heading 2",
  "Heading 3",
  "Heading 4",
  "Heading 5",
  "Heading 6",
  "Align Left",
  "Align Center",
  "Align Right",
  "Align Justify",
  "Align None",
  "Indent",
  "Outdent",
  "Numbered List",
  "Bullet List",
  "Foreground Color",
  "Background Color",
  "Remove Format",
  "Cut",
  "Copy",
  "Paste",
  "Remove",
  "Select All",
  "Blockquote",
  "Add/Edit Link",
  "Remove Link",
  "Add/Edit Image",
  "Add/Edit Media",
  "Table",
  "Horizontal Rule",
  "Edit Source Code",
  "Full Screen",
  "View invisible elements",
  "Directionality",
].map((s) => {
  return { value: s, label: s };
});

const FieldsConfigComponent = ({
  activeTab,
  fieldsList,
  dataSource,
  initialValues,
  nativeFieldType,
  setActiveTab,
  setIsDrawerOpen,
  init,
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const id = useId();
  const params = useParams();
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [collectionsList, setCollectionList] = useState([]);
  const [tags, setTags] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [selectedField, setSelectedField] = useState("String");
  const [value, setValue] = useState([]);
  const [choices, setChoices] = useState([]);
  const [activeChoice, setActiveChoice] = useState(null);
  const [selectedFields, setSelectedFields] = useState([]);
  const [open, setOpen] = useState(false);
  const [folders, setFolders] = useState(null);
  const [selectedTranslation, setSelectedTranslation] = useState(null);
  const [isTranslationDrawerOpen, setIsTranslationDrawerOpen] = useState(false);
  const [translationsList, setTranslationsList] = useState([]);

  useEffect(() => {
    getTranslations();
    ["wysiwyg", "file", "file-image", "files"].includes(activeTab) &&
      getFoldersList();
    ["list-m2m", "list-o2m", "list-m2o"].includes(activeTab) &&
      getCollectionsList();
  }, []);

  const getTranslations = async () => {
    try {
      const resp = await axiosInstance.get(Apis.GET_TRANSLATIONS("", true));
      if (resp.status === 200) setTranslationsList(resp.data.payload.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  const onCollectionChange = async (e) => {
    try {
      const resp = await axiosInstance.get(
        Apis.GET_ALL_FIELDS(
          collectionsList?.find((c) => c?.collection_id === e)?._id,
        ),
      );
      if (resp.status === 200) setSelectedFields(resp.data.payload.data);
    } catch (error) {
      console.log("Error", error);
    }
  };

  const getFoldersList = async () => {
    try {
      const resp = await axiosInstance.get(
        Apis.GET_ALL_FOLDERS({ pagination: "true" }),
      );
      if (resp.status === 200) {
        setFolders(resp.data?.payload?.data);
      }
    } catch (error) {
      notification.error({ message: t("something_went_wrong") });
    }
  };

  const columns = [
    {
      key: "sort",
      width: 50,
    },
    {
      dataIndex: "label",
      align: "left",
      width: "auto",
      render: (record, rowData, index) => {
        return (
          <div
            onClick={() => {
              setActiveChoice({ ...rowData, index });
              setOpen(true);
            }}
          >
            {record}
          </div>
        );
      },
    },
    {
      dataIndex: "label",
      key: "delete",
      width: 50,
      render: (record) => {
        return (
          <div
            onClick={() =>
              setChoices((prev) => prev?.filter((f) => f?.label !== record))
            }
          >
            <AiOutlineClose size={24} />
          </div>
        );
      },
    },
  ];

  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      setDataSource((previous) => {
        const activeIndex = previous.findIndex((i) => i.key === active.id);
        const overIndex = previous.findIndex((i) => i.key === over?.id);
        return arrayMove(previous, activeIndex, overIndex);
      });
    }
  };

  const handleInputConfirm = () => {
    if (inputValue && !tags.includes(inputValue)) {
      setTags([...tags, inputValue]);
    }
    setInputValue("");
  };

  const getCollectionsList = async () => {
    try {
      const resp1 = await axiosInstance.get(Apis.GET_ALL_SCHEMAS({}));
      if (resp1.status === 200)
        setCollectionList(resp1.data.payload.data?.filter((c) => c?.visible));
    } catch (error) {
      console.log("error", error);
    }
  };

  const getDefaultField = () => {
    switch (activeTab) {
      case "input":
        return selectedField === "Number" ? (
          <InputNumber
            id={`${id}-default`}
            className="min-h-14 flex items-center rounded-lg placeholder:font-normal px-3 w-full leading-10 overflow-hidden"
          />
        ) : (
          <Input
            placeholder={t("null")}
            id={`${id}-default`}
            className="min-h-14 rounded-xl placeholder:font-normal px-6"
            disabled={selectedField === "ObjectId"}
          />
        );
      case "autocomplete":
        return (
          <Input
            placeholder={t("null")}
            id={`${id}-default`}
            className="min-h-14 rounded-xl placeholder:font-normal px-6"
          />
        );
      case "textarea":
        return <TextArea id={`${id}-default`} className="font-normal" />;
      case "wysiwyg":
        return <ReactQuill theme="snow" />;
      case "tags":
        return (
          <div>
            <Input
              type="text"
              placeholder={t("add_a_tag_and_press_enter")}
              id={`${id}-presets`}
              className="min-h-14 rounded-xl placeholder:font-normal px-6"
              suffix={<BsTags size={20} color="gray" />}
              value={inputValue}
              onChange={(e) => {
                setInputValue(e.target.value);
              }}
              onPressEnter={handleInputConfirm}
            />
            <div style={{ marginTop: "16px" }}>
              {tags?.map((tag, index) => (
                <Tag
                  color="blue"
                  key={index}
                  onClick={() =>
                    setTags((prev) => prev?.filter((t) => t !== tag))
                  }
                  style={{
                    display: "inline-block",
                    padding: "5px",
                    background: "#f3f3f3",
                    margin: "5px",
                    borderRadius: "5px",
                  }}
                >
                  {tag}
                </Tag>
              ))}
            </div>
          </div>
        );
      case "toggle":
        return (
          <InvertedCheckBox id={`${id}-default`}>
            {t("enabled")}
          </InvertedCheckBox>
        );
      case "datetime":
        return (
          <DatePicker
            showTime
            size={"large"}
            id={`${id}-default`}
            className="min-h-14 rounded-xl placeholder:font-normal px-6 w-full"
          />
        );
      case "dropdown":
        return <Radio.Group options={choices} />;
      case "checkboxes":
        return <Checkbox.Group options={choices} />;
      case "dropdown_multiple":
        return <Checkbox.Group options={choices} />;
      case "radio":
        return <Radio.Group options={choices} />;
    }
  };

  const getFormConfig = () => {
    return fieldsList?.map((field) => {
      switch (field?.name) {
        case "name":
          return (
            <Col
              key={field.name}
              className="gutter-row"
              xs={24}
              md={field?.width === "half" ? 12 : 24}
            >
              <Form.Item
                name={field?.name}
                className="font-bold"
                label={t("key")}
                rules={[{ required: true }]}
                htmlFor={`${id}-input`}
              >
                <Input
                  placeholder={field?.placeholder}
                  id={`${id}-input`}
                  className="min-h-14 rounded-xl placeholder:font-normal px-6"
                  onChange={(e) => {
                    form.setFieldValue(
                      "name",
                      e.target.value
                        .replace(/[^a-zA-Z0-9_ ]/g, "")
                        .replace(/ /g, "_")
                        ?.toLowerCase(),
                    );
                  }}
                />
              </Form.Item>
            </Col>
          );
        case "group":
          return (
            <Col
              key={field.name}
              className="gutter-row"
              xs={24}
              md={field?.width === "half" ? 12 : 24}
            >
              <Form.Item
                name={field?.name}
                label={t("group")}
                className="font-bold"
                htmlFor={`${id}-group`}
              >
                <TreeSelect
                  id={`${id}-group`}
                  showSearch
                  dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                  placeholder={field?.placeholder}
                  allowClear
                  onClear={() => setSelectedGroup(null)}
                  treeDefaultExpandAll
                  onSelect={(_, e) => setSelectedGroup(e)}
                  treeData={convertToTreeData(
                    dataSource?.fields?.filter((f) => {
                      return ["Object"]?.includes(f?.type);
                    }),
                    "tree-select",
                  )}
                />
              </Form.Item>
            </Col>
          );
        case "type":
          return (
            <Col
              key={field.name}
              className="gutter-row"
              xs={24}
              md={field?.width === "half" ? 12 : 24}
            >
              <Form.Item
                name={field?.name}
                label={t("type")}
                htmlFor={`${id}-type`}
                className="font-bold"
                rules={[{ required: true }]}
              >
                <Select
                  size="large"
                  placeholder={field?.placeholder}
                  disabled={field?.fieldTypes?.disabled}
                  style={{ flex: 1, height: "56px" }}
                  id={`${id}-type`}
                  onChange={(e) => setSelectedField(e)}
                  options={field?.fieldTypes?.allowedTypes}
                />
              </Form.Item>
            </Col>
          );
        case "default":
          return (
            <Col
              key={field.name}
              className="gutter-row"
              xs={24}
              md={field?.width === "half" ? 12 : 24}
            >
              <Form.Item
                label={t("default_value")}
                htmlFor={`${id}-default`}
                className="font-bold"
                name={field?.name}
                valuePropName={activeTab === "toggle" ? "checked" : "value"}
              >
                {getDefaultField()}
              </Form.Item>
            </Col>
          );
        case "required":
          return (
            <Col
              key={field.name}
              className="gutter-row"
              xs={24}
              md={field?.width === "half" ? 12 : 24}
            >
              <Form.Item
                label={t("required")}
                htmlFor={`${id}-requireValue`}
                className="font-bold"
                name={field?.name}
                valuePropName="checked"
              >
                <InvertedCheckBox id={`${id}-requireValue`}>
                  {t("require_msg")}
                </InvertedCheckBox>
              </Form.Item>
            </Col>
          );
        case "url":
          return (
            <Col
              key={field.name}
              className="gutter-row"
              xs={24}
              md={field?.width === "half" ? 12 : 24}
            >
              <Form.Item
                label={t("url")}
                htmlFor={`${id}-url`}
                className="font-bold"
                name={field?.name}
              >
                <Input
                  placeholder={field?.placeholder}
                  id={`${id}-url`}
                  className="min-h-14 rounded-xl placeholder:font-normal px-6"
                />
              </Form.Item>
            </Col>
          );
        case "results_path":
          return (
            <Col key={field.name} className="gutter-row" xs={24}>
              <Form.Item
                label={t("results_path")}
                htmlFor={`${id}-resultsPath`}
                className="font-bold"
                name={field?.name}
              >
                <Input
                  placeholder={field?.placeholder}
                  id={`${id}-resultsPath`}
                  className="min-h-14 rounded-xl placeholder:font-normal px-6"
                />
              </Form.Item>
            </Col>
          );
        case "text_path":
          return (
            <Col
              key={field.name}
              className="gutter-row"
              xs={24}
              md={field?.width === "half" ? 12 : 24}
            >
              <Form.Item
                label={t("text_path")}
                htmlFor={`${id}-textPath`}
                className="font-bold"
                name={field?.name}
              >
                <Input
                  placeholder={field?.placeholder}
                  id={`${id}-textPath`}
                  className="min-h-14 rounded-xl placeholder:font-normal px-6"
                />
              </Form.Item>
            </Col>
          );
        case "value_path":
          return (
            <Col
              key={field.name}
              className="gutter-row"
              xs={24}
              md={field?.width === "half" ? 12 : 24}
            >
              <Form.Item
                label={t("value_path")}
                htmlFor={`${id}-ValuePath`}
                className="font-bold"
                name={field?.name}
              >
                <Input
                  placeholder={field?.placeholder}
                  id={`${id}-ValuePath`}
                  className="min-h-14 rounded-xl placeholder:font-normal px-6"
                />
              </Form.Item>
            </Col>
          );
        case "trigger":
          return (
            <Col
              key={field.name}
              className="gutter-row"
              xs={24}
              md={field?.width === "half" ? 12 : 24}
            >
              <Form.Item
                label={t("trigger")}
                htmlFor={`${id}-trigger`}
                className="font-bold"
                name={field?.name}
              >
                <Select
                  placeholder={field?.placeholder}
                  size="large"
                  style={{ flex: 1, height: "56px" }}
                  id={`${id}-trigger`}
                  options={[
                    {
                      value: "Throttle",
                      label: "Throttle",
                    },
                    {
                      value: "Debounce",
                      label: "Debounce",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
          );
        case "rate":
          return (
            <Col
              key={field.name}
              className="gutter-row"
              xs={24}
              md={field?.width === "half" ? 12 : 24}
            >
              <Form.Item
                label={t("rate")}
                htmlFor={`${id}-rate`}
                className="font-bold"
                name={`rate`}
              >
                <InputNumber
                  placeholder={field?.placeholder}
                  id={`${id}-rate`}
                  min={1}
                  max={1000}
                  className="min-h-14 flex items-center rounded-xl placeholder:font-normal px-3 w-full leading-10"
                />
              </Form.Item>
            </Col>
          );
        case "placeholder":
          return (
            <Col
              key={field.name}
              className="gutter-row"
              xs={24}
              md={field?.width === "half" ? 12 : 24}
            >
              <Form.Item
                label={t("placeholder")}
                htmlFor={`${id}-placeholder`}
                className="font-bold"
                name={field?.name}
              >
                <PlaceholderWithTranslations
                  form={form}
                  selectedTranslation={selectedTranslation}
                  setIsTranslationDrawerOpen={setIsTranslationDrawerOpen}
                  setSelectedTranslation={setSelectedTranslation}
                  translationsList={translationsList}
                />
              </Form.Item>
            </Col>
          );
        case "toolbar":
          return (
            <Col
              key={field.name}
              className="gutter-row"
              xs={24}
              md={field?.width === "half" ? 12 : 24}
            >
              <Form.Item
                label={t("toolbar")}
                htmlFor={`${id}-toolbar`}
                className="font-bold"
                name={field?.name}
              >
                <Select
                  mode={`multiple`}
                  style={{
                    width: "100%",
                  }}
                  options={options}
                  placeholder={field?.placeholder}
                  maxTagCount={`responsive`}
                  id={`${id}-toolbar`}
                  value={value}
                  onChange={setValue}
                />
              </Form.Item>
            </Col>
          );
        case "font":
          return (
            <Col
              key={field.name}
              className="gutter-row"
              xs={24}
              md={field?.width === "half" ? 12 : 24}
            >
              <Form.Item
                label={t("font")}
                htmlFor={`${id}-font`}
                className="font-bold"
                name={field?.name}
              >
                <Select
                  size="large"
                  placeholder={t("font")}
                  style={{ flex: 1, height: "56px" }}
                  id={`${id}-font`}
                  options={[
                    {
                      value: "Serif",
                      label: "Serif",
                    },
                    {
                      value: "Sans Serif",
                      label: "Sans Serif",
                    },
                    {
                      value: "Monospace",
                      label: "Monospace",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
          );
        case "folder":
          return (
            <Col
              key={field.name}
              className="gutter-row"
              xs={24}
              md={field?.width === "half" ? 12 : 24}
            >
              <Form.Item
                label={t("root_folder")}
                className="font-bold"
                htmlFor={`${id}-folder`}
                name={`folder`}
                help={
                  <p className="font-normal mt-2 opacity-40">
                    {t("folder_msg")}
                  </p>
                }
              >
                <TreeSelect
                  id={`${id}-folder`}
                  showSearch
                  dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                  placeholder={t("select_folder")}
                  allowClear
                  treeDefaultExpandAll
                  onSelect={(e) => form.setFieldValue("folder", e)}
                  treeData={convertToTreeFormat(folders)}
                />
              </Form.Item>
            </Col>
          );
        case "static_access_token":
          return (
            <Col
              key={field.name}
              className="gutter-row"
              xs={24}
              md={field?.width === "half" ? 12 : 24}
            >
              <Form.Item
                label={t("static_access_token")}
                htmlFor={`${id}-static_access_token`}
                className="font-bold"
                name={field?.name}
              >
                <div>
                  <Input
                    placeholder={t("static_access_token")}
                    id={`${id}-static_access_token`}
                    className="min-h-14 rounded-xl placeholder:font-normal px-6"
                  />
                  <span className="text-sm text-black font-light italic">
                    {"Static access token is appended to the assets' URL"}
                  </span>
                </div>
              </Form.Item>
            </Col>
          );

        case "alphabetize":
          return (
            <Col
              key={field.name}
              className="gutter-row"
              xs={24}
              md={field?.width === "half" ? 12 : 24}
            >
              <Form.Item
                label={t("alphabetize")}
                htmlFor={`${id}-Alphabetize`}
                className="font-bold"
                name={field?.name}
                valuePropName="checked"
              >
                <InvertedCheckBox id={`${id}-Alphabetize`}>
                  {t("force_alphabetical_order")}
                </InvertedCheckBox>
              </Form.Item>
            </Col>
          );

        case "allow_other":
          return (
            <Col
              key={field.name}
              className="gutter-row"
              xs={24}
              md={field?.width === "half" ? 12 : 24}
            >
              <Form.Item
                label={t("allow_other")}
                htmlFor={`${id}-allowOther`}
                className="font-bold"
                name={field?.name}
                valuePropName="checked"
              >
                <InvertedCheckBox id={`${id}-allowOther`}>
                  {t("allow_other_values")}
                </InvertedCheckBox>
              </Form.Item>
            </Col>
          );

        case "whitespace":
          return (
            <Col
              key={field.name}
              className="gutter-row"
              xs={24}
              md={field?.width === "half" ? 12 : 24}
            >
              <Form.Item
                label={t("whitespace")}
                htmlFor={`${id}-whitespace`}
                className="font-bold"
                name={field?.name}
              >
                <Select
                  size="large"
                  style={{ flex: 1, height: "56px" }}
                  id={`${id}-whitespace`}
                  options={[
                    {
                      value: "Replace with hyphen",
                      label: "Replace with hyphen",
                    },
                    {
                      value: "Replace with underscore",
                      label: "Replace with underscore",
                    },
                    {
                      value: "Remove whitespace",
                      label: "Remove whitespace",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
          );

        case "capitalization":
          return (
            <Col
              key={field.name}
              className="gutter-row"
              xs={24}
              md={field?.width === "half" ? 12 : 24}
            >
              <Form.Item
                label={t("capitalization")}
                htmlFor={`${id}-capitalization`}
                className="font-bold"
                name={field?.name}
              >
                <Select
                  size="large"
                  style={{ flex: 1, height: "56px" }}
                  id={`${id}-capitalization`}
                  options={[
                    {
                      value: "Convert Lowercase",
                      label: "Convert Lowercase",
                    },
                    {
                      value: "Use Title Auto-Formatter",
                      label: "Use Title Auto-Formatter",
                    },
                    {
                      value: "Convert Uppercase",
                      label: "Convert Uppercase",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
          );

        case "icon_left":
          return (
            <Col
              key={field.name}
              className="gutter-row"
              xs={24}
              md={field?.width === "half" ? 12 : 24}
            >
              <Form.Item
                label={t("icon_left")}
                htmlFor={`${id}-iconLeft`}
                className="font-bold"
                name={field?.name}
              >
                <Select
                  size="large"
                  style={{ flex: 1, height: "56px" }}
                  id={`${id}-iconLeft`}
                  options={[
                    {
                      value: "Icon 1",
                      label: "Icon 1",
                    },
                    {
                      value: "Icon 2",
                      label: "Icon 2",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
          );
        case "icon_right":
          return (
            <Col
              key={field.name}
              className="gutter-row"
              xs={24}
              md={field?.width === "half" ? 12 : 24}
            >
              <Form.Item
                label={t("icon_right")}
                htmlFor={`${id}-iconright`}
                className="font-bold"
                name={field?.name}
              >
                <Select
                  size="large"
                  style={{ flex: 1, height: "56px" }}
                  id={`${id}-iconright`}
                  options={[
                    {
                      value: "Icon 1",
                      label: "Icon 1",
                    },
                    {
                      value: "Icon 2",
                      label: "Icon 2",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
          );

        case "icon_on":
          return (
            <Col
              key={field.name}
              className="gutter-row"
              xs={24}
              md={field?.width === "half" ? 12 : 24}
            >
              <Form.Item
                label={t("Icon On")}
                htmlFor={`${id}-iconOn`}
                className="font-bold"
                name={field?.name}
              >
                <Select
                  size="large"
                  placeholder={t("search_for_color")}
                  style={{ flex: 1, height: "56px" }}
                  id={`${id}-iconOn`}
                  options={[
                    {
                      value: "Icon 01",
                      label: "Icon 01",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
          );
        case "icon_off":
          return (
            <Col
              key={field.name}
              className="gutter-row"
              xs={24}
              md={field?.width === "half" ? 12 : 24}
            >
              <Form.Item
                label={t("icon_off")}
                htmlFor={`${id}-iconOff`}
                className="font-bold"
                name={field?.name}
              >
                <Select
                  size="large"
                  placeholder={t("search_for_icon")}
                  style={{ flex: 1, height: "56px" }}
                  id={`${id}-iconOff`}
                  options={[
                    {
                      value: "Icon 01",
                      label: "Icon 01",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
          );
        case "color_on":
          return (
            <Col
              key={field.name}
              className="gutter-row"
              xs={24}
              md={field?.width === "half" ? 12 : 24}
            >
              <Form.Item
                label={t("color_on")}
                htmlFor={`${id}-colorOn`}
                className="font-bold"
                name={field?.name}
              >
                <Col
                  key={field.name}
                  orPicker
                  id={`${id}-colorOn`}
                  showText
                  className="w-full justify-start px-3 py-[14.08px]"
                  format="hex"
                  onChange={(_, hex) => form.setFieldsValue({ color_on: hex })}
                />
              </Form.Item>
            </Col>
          );
        case "color_off":
          return (
            <Col
              key={field.name}
              className="gutter-row"
              xs={24}
              md={field?.width === "half" ? 12 : 24}
            >
              <Form.Item
                label={t("color_off")}
                htmlFor={`${id}-colorOff`}
                className="font-bold"
                name={field?.name}
              >
                <Col
                  key={field.name}
                  orPicker
                  id={`${id}-colorOff`}
                  showText
                  className="w-full justify-start px-3 py-[14.08px]"
                  format="hex"
                  onChange={(_, hex) => form.setFieldsValue({ color_off: hex })}
                />
              </Form.Item>
            </Col>
          );
        case "label":
          return (
            <Col key={field.name} className="gutter-row" xs={24}>
              <Form.Item
                label={t("label")}
                htmlFor={`${id}-label`}
                className="font-bold"
                name={field?.name}
              >
                <Input
                  placeholder={t("Enter a Label...")}
                  id={`${id}-label`}
                  className="min-h-14 rounded-xl placeholder:font-normal px-6"
                  suffix={<IoLanguage size={20} color="gray" />}
                />
              </Form.Item>
            </Col>
          );
        case "include_seconds":
          return (
            <Col
              key={field.name}
              className="gutter-row"
              xs={24}
              md={field?.width === "half" ? 12 : 24}
            >
              <Form.Item
                label={t("include_seconds")}
                htmlFor={`${id}-includeSeconds`}
                className="font-bold"
                name={field?.name}
                valuePropName="checked"
              >
                <InvertedCheckBox id={`${id}-includeSeconds`}>
                  {t("enabled")}
                </InvertedCheckBox>
              </Form.Item>
            </Col>
          );
        case "use_24_hrs_format":
          return (
            <Col
              key={field.name}
              className="gutter-row"
              xs={24}
              md={field?.width === "half" ? 12 : 24}
            >
              <Form.Item
                label={t("use_24_hrs_format")}
                htmlFor={`${id}-UseFormat`}
                className="font-bold"
                name={field?.name}
                valuePropName="checked"
              >
                <InvertedCheckBox id={`${id}-UseFormat`} defaultChecked={true}>
                  {t("enabled")}
                </InvertedCheckBox>
              </Form.Item>
            </Col>
          );
        case "choices":
          return (
            <Col key={field.name} className="gutter-row" xs={24}>
              <Form.Item
                label={t("choices")}
                htmlFor={`${id}-new-field`}
                className="font-bold"
              >
                {!choices?.length &&
                  hasNewFieldFunc(
                    <BsInfoCircle size={20} />,
                    <p className="font-normal">Add a new choices</p>,
                  )}
                <Button
                  className="rounded-3xl  border border-solid border-grey-full bg-grey-full text-black px-5 py-3 mt-4 font-medium h-auto"
                  onClick={() => setOpen(true)}
                >
                  {t("create_new")}
                </Button>
              </Form.Item>
              {choices?.length > 0 && (
                <DndContext
                  modifiers={[restrictToVerticalAxis]}
                  onDragEnd={onDragEnd}
                >
                  <SortableContext
                    // rowKey array
                    items={choices?.map((c) => c?.value)}
                    strategy={verticalListSortingStrategy}
                  >
                    <TableSort
                      style={{ width: "100%" }}
                      pagination={false}
                      components={{
                        body: {
                          row: TableRow,
                        },
                      }}
                      rowKey={(record) => record?.label}
                      columns={columns}
                      dataSource={choices}
                      scroll={{
                        x: 300,
                      }}
                    />
                  </SortableContext>
                </DndContext>
              )}
            </Col>
          );
        case "allow_none":
          return (
            <Col
              key={field.name}
              className="gutter-row"
              xs={24}
              md={field?.width === "half" ? 12 : 24}
            >
              <Form.Item
                label={t("allow_none")}
                htmlFor={`${id}-no-selection`}
                className="font-bold"
                name={field?.name}
                valuePropName="checked"
              >
                <InvertedCheckBox
                  id={`${id}-no-selection`}
                  defaultChecked={false}
                >
                  {t("allow_no_selection")}
                </InvertedCheckBox>
              </Form.Item>
            </Col>
          );
        case "icon":
          return (
            <Col
              key={field.name}
              className="gutter-row"
              xs={24}
              md={field?.width === "half" ? 12 : 24}
            >
              <Form.Item
                label={t("icon")}
                htmlFor={`${id}-icon`}
                className="font-bold"
                name={field?.name}
              >
                <Select
                  size="large"
                  style={{ flex: 1, height: "56px" }}
                  id={`${id}-icon`}
                  options={[
                    {
                      value: "Manual",
                      label: "Manual",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
          );
        case "color":
          return (
            <Col
              key={field.name}
              className="gutter-row"
              xs={24}
              md={field?.width === "half" ? 12 : 24}
            >
              <Form.Item
                label={t("color")}
                htmlFor={`${id}-colorOn`}
                className="font-bold"
                name={field?.name}
              >
                <ColorPicker
                  id={`${id}-colorOn`}
                  showText
                  className="w-full justify-start px-3 py-[14.08px]"
                  format="hex"
                  onChange={(_, hex) => form.setFieldsValue({ color: hex })}
                />
              </Form.Item>
            </Col>
          );
        case "per_page":
          return (
            <Col
              key={field.name}
              className="gutter-row"
              xs={24}
              md={field?.width === "half" ? 12 : 24}
            >
              <Form.Item
                label={t("per_page")}
                htmlFor={`${id}-per_page`}
                className="font-bold"
                name={field?.name}
              >
                <InputNumber
                  id={`${id}-per_page`}
                  min={1}
                  max={10}
                  className="min-h-14 flex items-center rounded-xl placeholder:font-normal px-3 w-full leading-10"
                />
              </Form.Item>
            </Col>
          );
        case "crop":
          return (
            <Col
              key={field.name}
              className="gutter-row"
              xs={24}
              md={field?.width === "half" ? 12 : 24}
            >
              <Form.Item
                label={t("crop_to_fit")}
                htmlFor={`${id}-requireValue`}
                className="font-bold"
                name={field?.name}
                valuePropName="checked"
              >
                <InvertedCheckBox id={`${id}-requireValue`} checked>
                  {t("crop_image_as_needed")}
                </InvertedCheckBox>
              </Form.Item>
            </Col>
          );
        case "layout":
          return (
            <Col
              key={field.name}
              className="gutter-row"
              xs={24}
              md={field?.width === "half" ? 12 : 24}
            >
              <Form.Item
                label={t("layout")}
                htmlFor={`${id}-layout`}
                className="font-bold"
                name={field?.name}
              >
                <Select
                  showSearch
                  size="large"
                  style={{ flex: 1, height: "56px" }}
                  id={`${id}-layout`}
                  options={[
                    {
                      value: "List",
                      label: "List",
                    },
                    {
                      value: "Table",
                      label: "Table",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
          );
        case "foreign_key_table":
          return (
            <Col
              key={field.name}
              className="gutter-row"
              xs={24}
              md={field?.width === "half" ? 12 : 24}
            >
              <Form.Item
                label={t("related_collection")}
                className="font-bold"
                htmlFor={`${id}-related-collection`}
                rules={[
                  {
                    required: true,
                    message: t("related_collection_error"),
                  },
                ]}
                name={field?.name}
              >
                <Select
                  showSearch
                  size="large"
                  style={{ flex: 1, height: "56px" }}
                  id={`${id}-related-collection`}
                  options={collectionsList
                    ?.filter((c) => c?._id !== params.field)
                    ?.map((c) => {
                      return {
                        label: c?.collection_id,
                        value: c?.collection_id,
                      };
                    })}
                  onChange={onCollectionChange}
                />
              </Form.Item>
            </Col>
          );
        case "creating_items":
          return (
            <Col
              key={field.name}
              className="gutter-row"
              xs={24}
              md={field?.width === "half" ? 12 : 24}
            >
              <Form.Item
                label={t("creating_items")}
                htmlFor={`${id}-create-item`}
                className="font-bold"
                name={field?.name}
                valuePropName="checked"
              >
                <InvertedCheckBox id={`${id}-create-item`} checked>
                  {t("enable_create_button")}
                </InvertedCheckBox>
              </Form.Item>
            </Col>
          );
        case "selecting_items":
          return (
            <Col
              key={field.name}
              className="gutter-row"
              xs={24}
              md={field?.width === "half" ? 12 : 24}
            >
              <Form.Item
                label={t("selecting_items")}
                htmlFor={`${id}-select-value`}
                className="font-bold"
                name={field?.name}
                valuePropName="checked"
              >
                <InvertedCheckBox id={`${id}-select-value`} checked>
                  {t("enable_select_button")}
                </InvertedCheckBox>
              </Form.Item>
            </Col>
          );
        case "junction_fields_location":
          return (
            <Col
              key={field.name}
              className="gutter-row"
              xs={24}
              md={field?.width === "half" ? 12 : 24}
            >
              <Form.Item
                label={t("junction_field_location")}
                htmlFor={`${id}-field-location`}
                className="font-bold"
                name={field?.name}
              >
                <Select
                  showSearch
                  size="large"
                  style={{ flex: 1, height: "56px" }}
                  id={`${id}-field-location`}
                  options={[
                    {
                      value: "Bottom",
                      label: "Bottom",
                    },
                    {
                      value: "Top",
                      label: "Top",
                    },
                    {
                      value: "Right",
                      label: "Right",
                    },
                    {
                      value: "Left",
                      label: "Left",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
          );
        case "allow_duplicates":
          return (
            <Col
              key={field.name}
              className="gutter-row"
              xs={24}
              md={field?.width === "half" ? 12 : 24}
            >
              <Form.Item
                label={t("allow_duplicates")}
                htmlFor={`${id}-allow-duplicates`}
                className="font-bold"
                name={field?.name}
                valuePropName="checked"
              >
                <InvertedCheckBox id={`${id}-allow-duplicates`}>
                  {t("enabled")}
                </InvertedCheckBox>
              </Form.Item>
            </Col>
          );
        case "item_link":
          return (
            <Col
              key={field.name}
              className="gutter-row"
              xs={24}
              md={field?.width === "half" ? 12 : 24}
            >
              <Form.Item
                label={t("item_link")}
                htmlFor={`${id}-item-link`}
                className="font-bold"
                name={field?.name}
                valuePropName="checked"
              >
                <InvertedCheckBox id={`${id}-item-link`}>
                  {t("item_link_msg")}
                </InvertedCheckBox>
              </Form.Item>
            </Col>
          );
        case "foreign_key_column":
          return (
            <Col
              key={field.name}
              className="gutter-row"
              xs={24}
              md={field?.width === "half" ? 12 : 24}
            >
              <Form.Item
                label={t("foreign_key")}
                className="font-bold"
                htmlFor={`${id}-foreign-key`}
                rules={[
                  {
                    required: true,
                    message: t("foreign_key_error"),
                  },
                ]}
                name={field?.name}
              >
                <Select
                  showSearch
                  size="large"
                  style={{ flex: 1, height: "56px" }}
                  id={`${id}-foreign-key`}
                  options={selectedFields?.map((c) => {
                    return {
                      label: c?.field,
                      value: c?.field,
                      disabled: c?.field === "_id",
                    };
                  })}
                />
              </Form.Item>
            </Col>
          );
        case "display_template":
          return (
            <Col
              key={field.name}
              className="gutter-row"
              xs={24}
              md={field?.width === "half" ? 12 : 24}
            >
              <Form.Item
                label={t("display_template")}
                className="font-bold"
                htmlFor={`${id}-display-template`}
                name={field?.name}
              >
                <Select
                  showSearch
                  size="large"
                  style={{ flex: 1, height: "56px" }}
                  id={`${id}-display-template`}
                  options={selectedFields?.map((c) => {
                    return { label: c?.field, value: c?.field };
                  })}
                />
              </Form.Item>
            </Col>
          );
      }
    });
  };

  const handleSubmit = async () => {
    let isError;
    try {
      const values = await form.validateFields();
      isError = dataSource?.fields?.some((f) => f?.field === values.name);
      if (isError) {
        notification.error({ message: t("unique_key_error") });
        return;
      }
      let payload = {
        schema_id: params.field,
        field: values.name,
        type: values.type,
        path: selectedGroup?.value
          ? selectedGroup?.value + "." + values?.name
          : values?.name,
        field_type: selectedGroup ? "Object" : nativeFieldType,
        ref: values?.foreign_key_table,
        meta: {
          field: values.name,
          interface: activeTab,
          width: "full",
          sort: dataSource?.fields?.length + 1,
          nullable: true,
          options: {
            related_collections: values.related_collections,
            per_page: values.per_page,
            allow_duplicates: values.allow_duplicates,
            item_link: values.item_link,
            layout: values.layout,
            creating_items: values.creating_items,
            selecting_items: values.selecting_items,
            junction_fields_location: values.junction_fields_location,
            icon_left: values.icon_left,
            icon_right: values.icon_right,
            placeholder: selectedTranslation
              ? "$t:" + values.placeholder
              : values.placeholder,
            url: values.url,
            resultsPath: values.results_path,
            textPath: values.text_path,
            value_path: values.value_path,
            trigger: values.trigger,
            font: values.font,
            static_access_token: values.static_access_token,
            toolbar: values.toolbar,
            alphabetize: values.alphabetize,
            allow_other: values.allow_other,
            whitespace: values.whitespace,
            capitalization: values.capitalization,
            icon_on: values.icon_on,
            icon_off: values.icon_off,
            color_on: values.color_on,
            color_off: values.color_off,
            label: values.label,
            include_seconds: values.include_seconds,
            use_24_hrs_format: values.use_24_hrs_format,
            choices: choices,
            allow_none: values.allow_none,
            icon: values.icon,
            color: values.color,
            crop: values.crop,
            display_template: values.display_template,
          },
          folder: values.folder,
          hidden: false,
          required: !!values.required,
        },
        schema_definition: {
          name: values.name,
          type: values.type,
          default: activeTab === "tags" ? tags : values.default,
          foreign_key_table: values.foreign_key_table,
          foreign_key_column: values.foreign_key_column,
          foreign_key_table_id: collectionsList?.find(
            (c) => c?.collection_id === values.foreign_key_table,
          )?._id,
        },
      };
      const resp = await axiosInstance.post(Apis.ADD_A_FIELD(), {
        ...removeNullAndUndefined(payload),
        totalFields: dataSource?.fields?.length,
      });
      if (resp.status === 200)
        notification.success({ message: t("field_add_success") });
      init();
      setActiveTab(null);
    } catch (error) {
      console.log("error", error);
      notification.error({ message: t("something_went_wrong") });
      isError = true;
    } finally {
      !isError && setIsDrawerOpen(false);
    }
  };

  return (
    <>
      <FormStyling
        form={form}
        layout="vertical"
        className="mt-12"
        initialValues={initialValues}
      >
        <Row gutter={28}>
          {getFormConfig()}
          <Col className="gutter-row" xs={24}>
            <Button
              onClick={handleSubmit}
              className="rounded-3xl bg-btn-dark text-white px-5 py-3 mt-4 font-medium h-auto w-full"
            >
              {t("save")}
            </Button>
            {/* <Button type="link" className="mt-4 font-medium h-auto w-full">
              Continue in Advanced Field Creation Mode
            </Button> */}
          </Col>
        </Row>
      </FormStyling>
      <DrawerStyle
        width={870}
        destroyOnClose
        open={open}
        onClose={() => {
          setOpen(false);
          setActiveChoice(null);
        }}
      >
        <CreateChoices
          setChoices={setChoices}
          setOpen={setOpen}
          choiceType={activeTab}
          activeChoice={activeChoice}
        />
      </DrawerStyle>
      <DrawerStyle
        width={870}
        open={isTranslationDrawerOpen}
        onClose={() => setIsTranslationDrawerOpen(false)}
        closeIcon={<IconsAll.CloseIconCustom />}
        destroyOnClose
      >
        <AddVertalingen
          setIsTranslationDrawerOpen={setIsTranslationDrawerOpen}
          type={"drawer"}
          getTranslations={getTranslations}
        />
      </DrawerStyle>
    </>
  );
};

export default FieldsConfigComponent;
