import React, { useEffect, useId } from "react";
import { Form, Row, Col, Flex, Input } from "antd";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { themes } from "../../../config";
import LeftSideTitlePart from "../../HeaderParts/LeftSideTitlePart";
import IconsAll from "../../IconsAll";
import RightSIdeTitlePart from "../../HeaderParts/RightSIdeTitlePart";
import { IoMdArrowBack } from "react-icons/io";
import { IoArrowForward } from "react-icons/io5";
import { capitalizeAndFormat } from "../../../helpers/utils";

const FormStyling = styled(Form)`
  background-color: var(--form-input-wrapper);
  border-top: 1px solid
    ${() => themes[useSelector((state) => state.theme.name)].token.grayClr};
  border-bottom: 1px solid
    ${() => themes[useSelector((state) => state.theme.name)].token.grayClr};
  padding: 34px;

  .relation-arrow {
    position: absolute;
    bottom: 50%;
    left: 0;
    transform: translate(-50%, -50%);
  }
`;

const UpdateRelationShip = ({ activeItem, dataSource }) => {
  const { t } = useTranslation();
  const id = useId();
  const [form] = Form.useForm();

  useEffect(() => {
    setFormData();
  }, [activeItem?._id]);

  const setFormData = async () => {
    await form.setFieldsValue({
      this_collection: dataSource?.collection_id,
      this_field:
        activeItem?.meta?.interface === "list-m2o" ? activeItem?.field : "_id",
      foreign_collection: activeItem?.schema_definition?.foreign_key_table,
      foreign_field:
        activeItem?.meta?.interface === "list-o2m"
          ? activeItem?.schema_definition?.foreign_key_column
          : "_id",
      ...(activeItem?.meta?.interface === "list-m2m" && {
        junction_collection: activeItem?.meta?.junction_collection,
        junction_field_this: dataSource?.collection_id + "_id",
        junction_field_foreign:
          activeItem?.schema_definition?.foreign_key_table + "_id",
      }),
    });
  };

  return (
    <Flex wrap="wrap" align="center" justify="space-between">
      <LeftSideTitlePart
        pageTitle={
          capitalizeAndFormat(activeItem?.meta?.interface) +
          " " +
          capitalizeAndFormat(activeItem?.type) +
          " (" +
          capitalizeAndFormat(dataSource?.collection_name) +
          ")"
        }
        pageIcon={true}
        pageInnerIcon={<IconsAll.ContentIcon strokeColor={"#3586c7"} />}
      />
      <RightSIdeTitlePart showButton showDisabled />
      <FormStyling form={form} layout="vertical" className="mt-4 w-full">
        <Row gutter={28}>
          <Col
            className="gutter-row"
            xs={24}
            md={activeItem?.meta?.interface === "list-m2m" ? 8 : 12}
          >
            <Form.Item
              label={t("this_collection")}
              htmlFor={`${id}-this_collection`}
              className="font-bold"
              name={`this_collection`}
            >
              <Input disabled id={`${id}-this_collection`} className="h-14" />
            </Form.Item>
          </Col>
          {activeItem?.meta?.interface === "list-m2m" && (
            <Col className="gutter-row" xs={24} md={8}>
              <Form.Item
                label={t("junction_collection")}
                htmlFor={`${id}-junction_collection`}
                className="font-bold"
                name={`junction_collection`}
              >
                <Input
                  disabled
                  id={`${id}-junction_collection`}
                  className="h-14"
                />
              </Form.Item>
            </Col>
          )}

          <Col
            className="gutter-row"
            xs={24}
            md={activeItem?.meta?.interface === "list-m2m" ? 8 : 12}
          >
            <Form.Item
              label={t("foreign_collection")}
              htmlFor={`${id}-foreign_collection`}
              className="font-bold"
              name={`foreign_collection`}
            >
              <Input
                disabled
                id={`${id}-foreign_collection`}
                className="h-14"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={28}>
          <Col
            className="gutter-row"
            xs={24}
            md={activeItem?.meta?.interface === "list-m2m" ? 8 : 12}
          >
            <Form.Item
              htmlFor={`${id}-this_field`}
              className="font-bold"
              name={`this_field`}
            >
              <Input disabled id={`${id}-this_field`} className="h-14" />
            </Form.Item>
          </Col>
          {activeItem?.meta?.interface === "list-m2m" && (
            <Col className="gutter-row" xs={24} md={8}>
              <span className="relation-arrow">
                <IoArrowForward />
              </span>
              <Form.Item
                htmlFor={`${id}-junction_field_this`}
                className="font-bold"
                name={`junction_field_this`}
              >
                <Input
                  disabled
                  id={`${id}-junction_field_this`}
                  className="h-14"
                />
              </Form.Item>
            </Col>
          )}
          {activeItem?.meta?.interface === "list-m2m" && (
            <Col className="gutter-row" xs={24} md={8} offset={8}>
              <Form.Item
                htmlFor={`${id}-junction_field_foreign`}
                className="font-bold"
                name={`junction_field_foreign`}
              >
                <Input
                  disabled
                  id={`${id}-junction_field_foreign`}
                  className="h-14"
                />
              </Form.Item>
            </Col>
          )}
          <Col
            className="gutter-row"
            xs={24}
            md={activeItem?.meta?.interface === "list-m2m" ? 8 : 12}
          >
            <span className="relation-arrow">
              {activeItem?.meta?.interface === "list-o2m" ? (
                <IoArrowForward />
              ) : (
                <IoMdArrowBack />
              )}
            </span>
            <Form.Item
              htmlFor={`${id}-foreign_field`}
              className="font-bold"
              name={`foreign_field`}
            >
              <Input disabled id={`${id}-foreign_field`} className="h-14" />
            </Form.Item>
          </Col>
        </Row>
      </FormStyling>
    </Flex>
  );
};

export default UpdateRelationShip;
