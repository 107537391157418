import React from "react";
import FormGenerator from "./FormGenerator";
import { capitalizeAndFormat, convertToTreeData } from "../../helpers/utils";
import { useSelector } from "react-redux";
import { Col } from "antd";

const TreeItem = ({
  item,
  setFormData,
  formData,
  updateFormData,
  validationErrors,
  depth = 0,
}) => {
  const currentLng = useSelector((state) => state?.user?.userProfile?.language);
  return (
    <div style={{ paddingLeft: depth * 40 }}>
      <FormGenerator
        fieldName={
          item?.meta?.translations?.find((t) => t?.language === currentLng)
            ?.translation || capitalizeAndFormat(item?.field)
        }
        fieldType={item.meta.interface}
        width={item.meta.width === "half" ? 12 : 24}
        item={item}
        setFormData={setFormData}
        formData={formData}
        updateFormData={updateFormData}
        validationErrors={validationErrors}
      />
    </div>
  );
};

const Tree = ({
  items,
  depth = 0,
  setFormData,
  formData,
  updateFormData,
  validationErrors,
}) => {
  return (
    <>
      <Col span={24}>
        {items?.map((item, i) => (
          <React.Fragment key={item?._id}>
            <TreeItem
              id={item?._id}
              depth={depth || 0}
              item={item}
              setFormData={setFormData}
              formData={formData}
              updateFormData={updateFormData}
              validationErrors={validationErrors}
              key={item.path + i}
            />
            {item?.children && (
              <Tree
                items={item?.children}
                depth={depth + 1}
                setFormData={setFormData}
                formData={formData}
                updateFormData={updateFormData}
                validationErrors={validationErrors}
              />
            )}
          </React.Fragment>
        ))}
      </Col>
    </>
  );
};

const DynamicForm = ({
  form = [],
  setFormData,
  formData,
  validationErrors,
}) => {
  const updateFormData = ({ key, value }) => {
    setFormData((prev) => {
      return { ...prev, [key]: value };
    });
  };

  return (
    <>
      <Tree
        items={convertToTreeData(form, "tree-fields")}
        setFormData={setFormData}
        formData={formData}
        updateFormData={updateFormData}
        validationErrors={validationErrors}
      />
    </>
  );
};

export default DynamicForm;
