import { Input, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { FiPlus } from "react-icons/fi";
import ContentTable from "../../../Pages/Content/ContentTable";
import { RiDeleteBin6Line } from "react-icons/ri";
import { getTranslatedLabel } from "../../../helpers/utils";
import { DrawerStyle } from "../../../Common/CommonUiStyles";

const CustomRelationalField = ({
  item,
  updateFormData,
  formData,
  isValidationError,
}) => {
  const [showDrawer, setShowDrawer] = useState(false);
  const [relationalData, setRelationalData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const placeholder = getTranslatedLabel(item?.meta?.options?.placeholder);

  useEffect(() => {
    setRelationalData(
      item?.meta?.interface === "list-m2o"
        ? formData?.[item?.path]
          ? [formData?.[item?.path]]
          : []
        : formData?.[item?.path]
          ? formData?.[item?.path]?.map((item) => item?._id)
          : [],
    );
  }, []);

  useEffect(() => {
    updateFormData({
      key: item.path,
      value:
        item?.meta?.interface === "list-m2o"
          ? relationalData?.length
            ? relationalData[0]
            : null
          : relationalData,
    });
  }, [relationalData]);

  return (
    <>
      {(item?.meta?.interface !== "list-m2o" || !relationalData?.length) && (
        <Input
          suffix={
            <Tooltip title={!item?.meta?.readonly ? "Add Relation" : ""}>
              <FiPlus
                size={24}
                onClick={() => {
                  if (!item?.meta?.readonly) setShowDrawer(true);
                }}
                className={!item?.meta?.readonly ? "cursor-pointer" : ""}
              />
            </Tooltip>
          }
          defaultValue={item?.schema_definition?.default}
          placeholder={placeholder}
          id={item?._id}
          disabled={!!item?.meta?.readonly}
          // value={formData?.[item.path]}
          onChange={(e) =>
            updateFormData({ key: item.path, value: e.target.value })
          }
          className={`min-h-14 rounded-xl placeholder:font-normal px-3 font-normal cursor-pointer ${isValidationError ? "border-[red]" : ""}`}
        />
      )}

      {relationalData?.map((d, i) => {
        return (
          <div
            key={i}
            className="flex justify-between border border-[#E1E3E6] p-3 my-3 rounded-xl items-center dark:border-[#2C3C54]"
          >
            <ul className="flex gap-4 flex-1 items-center">
              <li className="font-normal">{d}</li>
            </ul>
            <ul className="flex gap-4 items-center">
              <li>
                <button
                  type="button"
                  title="Delete"
                  onClick={() =>
                    setRelationalData((prev) => prev?.filter((e) => e !== d))
                  }
                >
                  <Tooltip title="Delete Item">
                    <RiDeleteBin6Line size={24} />
                  </Tooltip>
                </button>
              </li>
            </ul>
          </div>
        );
      })}

      <DrawerStyle
        width={870}
        open={showDrawer}
        className="table-in-drawer"
        onClose={() => {
          setShowDrawer(false), setSelectedRows([]);
        }}
        destroyOnClose
      >
        <ContentTable
          tableType={`relations`}
          tableId={item?.schema_definition?.foreign_key_table_id}
          setShowDrawer={setShowDrawer}
          setRelationalData={setRelationalData}
          relationalData={relationalData}
          item={item}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
        />
      </DrawerStyle>
    </>
  );
};

export default CustomRelationalField;
