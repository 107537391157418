import { Col, Form, Row } from "antd";
import React, { useEffect, useState } from "react";
import LeftSideTitlePart from "../../Components/HeaderParts/LeftSideTitlePart";
import TenantListTable from "./TenantListTable";
import Tenant from "./Tenant";
import IconsAll from "../../Components/IconsAll";
import RightSIdeTitlePart from "../../Components/HeaderParts/RightSIdeTitlePart";
import Apis from "../../helpers/Apis";
import axiosInstance from "../../helpers/axios";
import { useTranslation } from "react-i18next";
import AppInfo from "../../Components/AppInfo";
import { DrawerStyle } from "../../Common/CommonUiStyles";

const TenantList = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [tenants, setTenants] = useState([]);
  const [activeTenant, setActiveTenant] = useState(null);
  const [form] = Form.useForm();
  const { t } = useTranslation();

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    const resp = await axiosInstance.get(Apis.GET_ALL_TENANTS());
    if (resp.status === 200) setTenants(resp?.data?.payload?.data || []);
  };

  return (
    <Row className="main-content-wrap">
      <Col className="px-8 xl:px-16 left-part">
        <div className="flex flex-wrap md:items-center md:justify-between mt-9 mb-11">
          <LeftSideTitlePart
            pageTitle={t("users")}
            pageIcon={false}
            backButton={false}
          />
          <RightSIdeTitlePart
            addButton={true}
            isDrawerOpen={isDrawerOpen}
            setIsDrawerOpen={setIsDrawerOpen}
          />
          <DrawerStyle
            width={870}
            open={isDrawerOpen}
            closeIcon={<IconsAll.CloseIconCustom />}
            onClose={() => {
              setIsDrawerOpen(false), setActiveTenant(null), form.resetFields();
            }}
          >
            <Tenant
              form={form}
              setIsDrawerOpen={setIsDrawerOpen}
              setActiveTenant={setActiveTenant}
              init={init}
              activeTenant={activeTenant}
            />
          </DrawerStyle>
        </div>
        <section className="mt-9">
          <TenantListTable
            tenants={tenants}
            setIsDrawerOpen={setIsDrawerOpen}
            setActiveTenant={setActiveTenant}
          />
        </section>
      </Col>
      <Col className="right-part">
        <AppInfo />
      </Col>
    </Row>
    /* <Flex className="w-full">

      <div className="px-4 xl:px-16 w-full">
        <div className="flex flex-wrap md:items-center md:justify-between mt-9 mb-11">
          <LeftSideTitlePart
            pageTitle={t("users")}
            pageIcon={false}
            backButton={false}
          />
          <RightSIdeTitlePart
            addButton={true}
            isDrawerOpen={isDrawerOpen}
            setIsDrawerOpen={setIsDrawerOpen}
          />
          <DrawerStyle
            width={870}
            open={isDrawerOpen}
            onClose={() => {
              setIsDrawerOpen(false), setActiveTenant(null), form.resetFields();
            }}
            closeIcon={<IconsAll.CloseIconCustom />}
          >
            <Tenant
              form={form}
              setIsDrawerOpen={setIsDrawerOpen}
              setActiveTenant={setActiveTenant}
              init={init}
              activeTenant={activeTenant}
            />
          </DrawerStyle>
        </div>
        <section className="mt-9">
          <TenantListTable
            tenants={tenants}
            setIsDrawerOpen={setIsDrawerOpen}
            setActiveTenant={setActiveTenant}
          />
        </section>
      </div>
      <AppInfo />
    </Flex> */
  );
};

export default TenantList;
