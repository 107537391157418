import React from "react";
import Apis from "./Apis";
import axiosInstance from "./axios";
import { useTranslation } from "react-i18next";
import { store } from "../Redux/store";
import { NotHasField } from "../Common/CommonUiStyles";
import { MenuOutlined } from "@ant-design/icons";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

export const objectId = (value) => {
  return value.match(/^[0-9a-fA-F]{24}$/);
};

export async function retrieveLoginPrompt() {
  try {
    let response = await axiosInstance.get(Apis.LOGIN());
    return response;
  } catch (err) {
    console.log(err);
    throw err;
  }
}

export const tabletBreakpoint = 1279;

export function capitalizeAndFormat(str) {
  return str
    ?.split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

export const getTranslatedLabel = (label) => {
  const { t } = useTranslation("tenant");
  return t(label?.replace("$t:", ""));
};

export const convertToTreeData = (fields, type) => {
  const root = [];
  const fieldMap = new Map();
  const currentLng = store.getState().user?.userProfile?.language;

  fields?.forEach((field) => {
    const pathParts = field?.path?.split(".") || [field?.path];
    let parentPathKey = "";

    pathParts.forEach((part, ind) => {
      const pathKey = pathParts.slice(0, ind + 1).join(".");
      let existingNode = fieldMap.get(pathKey);
      if (!existingNode) {
        delete field?.ref;

        switch (type) {
          case "tree-select":
            existingNode = {
              label: part,
              value: field?.path,
              field_type: field?.field_type,
              children: [],
            };
            break;
          case "tree-fields":
            existingNode = {
              ...field,
              title: part,
              value: field?.path,
              children: [],
              key: field?.path,
            };
            break;
          case "tree-columns":
            existingNode = {
              label:
                field?.meta?.translations?.find(
                  (t) => t?.language === currentLng,
                )?.translation || part,
              key: field?.path,
              value: field?.path,
              children: [],
              fields: [{ path: pathKey, field_id: field?._id }],
              _id: field?._id,
            };
            break;

          default:
            existingNode = {
              ...field,
              title: part,
              value: field?.path,
              children: [],
            };
            break;
        }

        if (ind === 0) {
          root.push(existingNode);
        } else {
          const parentField = fieldMap.get(parentPathKey);
          if (parentField) {
            if (type === "tree-columns" && !parentField.children) {
              parentField.children = [];
            }
            type === "tree-columns" &&
              existingNode.fields.unshift(...(parentField?.fields || []));
            parentField.children.push(existingNode);
          }
        }
        fieldMap.set(pathKey, existingNode);
      }
      if (
        type === "tree-columns" &&
        (!existingNode.children || existingNode.children.length === 0)
      ) {
        delete existingNode.children;
      }
      parentPathKey = pathKey;
    });
  });
  return root;
};

export const downloadFile = (url, fileName) => {
  const link = document.createElement("a");
  link.href = url;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const getDisplayImage = async (img_id, height, width) => {
  try {
    const resp2 = await axiosInstance.get(
      Apis.GET_DISPLAY_IMAGE(img_id, height, width),
      {
        responseType: "blob",
      },
    );
    if (resp2.status === 200) return URL.createObjectURL(resp2.data);
  } catch (error) {
    return null;
  }
};

export const removeNullAndUndefined = (obj) => {
  Object.entries(obj).forEach(([key, value]) => {
    if (value === undefined || value === null) {
      delete obj[key];
    } else if (Array.isArray(value) && value.length < 1) {
      delete obj[key];
    } else if (typeof value === "object") {
      removeNullAndUndefined(value);
    }
  });
  return obj;
};

export const hasNewFieldFunc = (icon, text) => {
  return (
    <NotHasField className="items-center gap-3">
      {icon}
      {text}
    </NotHasField>
  );
};

export const TableRow = ({ children, ...props }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props["data-row-key"],
  });
  const style = {
    ...props.style,
    transform: CSS.Transform.toString(
      transform && {
        ...transform,
        scaleY: 1,
      },
    ),
    transition,
    ...(isDragging
      ? {
          position: "relative",
          zIndex: 9999,
        }
      : {}),
  };
  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, (child) => {
        if (child.key === "sort") {
          return React.cloneElement(child, {
            children: (
              <MenuOutlined
                ref={setActivatorNodeRef}
                style={{
                  touchAction: "none",
                  cursor: "move",
                }}
                {...listeners}
              />
            ),
          });
        }
        return React.cloneElement(child, { key: child.key || index });
      })}
    </tr>
  );
};
