import React from "react";
import { CiFileOn } from "react-icons/ci";

const FileExtension = ({ size, extension }) => {
  return (
    <div className="relative file-icon">
      <CiFileOn size={size} />
      <span className="file-icon-title">
        {extension?.length > 4 ? "File" : extension}
      </span>
    </div>
  );
};

export default FileExtension;
