import React, { useEffect, useState } from "react";
import { Flex, Table, Button, notification } from "antd";
import LeftSideTitlePart from "../../Components/HeaderParts/LeftSideTitlePart";
import RightSIdeTitlePart from "../../Components/HeaderParts/RightSIdeTitlePart";
import { styled } from "styled-components";
import Add from "../../Assets/images/Icon/add_pluse.svg";
import Eye from "../../Assets/images/Icon/eye.svg";
import Edit from "../../Assets/images/Icon/edit.svg";
import DeleteIcon from "../../Assets/images/Icon/delete.svg";
import TickIcon from "../../Assets/images/Icon/tick-icon.svg";
import BlockIcon from "../../Assets/images/Icon/block-icon.svg";
import { IoIosArrowDown } from "react-icons/io";
import AppInfo from "../../Components/AppInfo";
import Title from "antd/es/typography/Title";
import { themes } from "../../config";
import { useSelector, useDispatch } from "react-redux";
import { setActiveTab } from "../../Redux/slices/activeTab.reducer";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Apis from "../../helpers/Apis";
import axiosInstance from "../../helpers/axios";
import { BiSelectMultiple } from "react-icons/bi";

const TableStripe = styled(Table)`
  .ant-table {
    background-color: ${() =>
      themes[useSelector((state) => state.theme.name)].components.Table
        .tableBg};
    border: 1px solid hsla(var(--input-field-border-color), 0.25);
    border-bottom: 0 !important;
    border-radius: 8px;
    margin-top: 10px;

    .gray-clr {
      color: ${() =>
        themes[useSelector((state) => state.theme.name)].token.grayClr};
    }

    tbody {
      font-family: "Sometype Mono", monospace;
    }

    .ant-table-cell {
      &.ant-table-column-has-sorters {
        &:hover {
          background-color: ${() =>
            themes[useSelector((state) => state.theme.name)].components.Table
              .sortedBg} !important;
        }
      }

      &.ant-table-column-sort {
        background-color: ${() =>
          themes[useSelector((state) => state.theme.name)].components.Table
            .sortedBg};
      }
    }

    .ant-table-thead {
      .ant-table-cell {
        border-bottom: 1.5px solid #899099;
      }
    }

    .ant-table-row:nth-child(odd) {
      background: var(--table-odd-row-bg);
    }
  }
`;

const SystemCollectionButtonStyle = styled(Button)`
  &.system-collection-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-size: 16px;
    border-radius: 0px 0px 8px 8px;
    background: var(--system-collection-button-bg);
    border-bottom: 1px solid hsla(var(--input-field-border-color), 0.25);
    border-left: 1px solid hsla(var(--input-field-border-color), 0.25);
    border-right: 1px solid hsla(var(--input-field-border-color), 0.25);
    color: var(--system-collection-button-text-clr);
    border-top: 0;
    margin-top: -6px;
    padding: 26px 10px;
    width: 100%;
    &:hover {
      background: #d0ddee !important;
      border-bottom: 1px solid #e1e3e6 !important;
      border-left: 1px solid #e1e3e6 !important;
      border-right: 1px solid #e1e3e6 !important;
      border-top: 0;
    }
  }
`;

// const clearText = (e) => {
//   console.log(e);
// };

// const tags = [
//   { value: "Academy", label: "Academy" },
//   { value: "leren en werken", label: "leren en werken" },
// ];

const Permissions = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { field } = useParams();
  const [roleData, setRoleData] = useState(null);

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    try {
      const resp = await axiosInstance.get(Apis.GET_A_ROLE(field));
      if (resp.status === 200) setRoleData(resp.data.payload.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  const columns = [
    {
      title: t("collection"),
      dataIndex: "module_name",
      key: "collectie",
    },
    {
      title: <img src={Add} alt="add" className="max-w-6" />,
      dataIndex: "can_add",
      key: "can_add",
      width: 50,
      render: (record, rowData) => {
        return (
          <div
            onClick={() =>
              handlePermissionToggle("can_add", record, rowData?.module_id)
            }
          >
            <img
              src={record ? TickIcon : BlockIcon}
              alt=""
              className="max-w-6"
            />
          </div>
        );
      },
    },
    {
      title: <img src={Eye} alt="eye" className="max-w-6" />,
      dataIndex: "can_read",
      key: "can_read",
      width: 50,
      render: (record, rowData) => {
        return (
          <div
            onClick={() =>
              handlePermissionToggle("can_read", record, rowData?.module_id)
            }
          >
            <img
              src={record ? TickIcon : BlockIcon}
              alt=""
              className="max-w-6"
            />
          </div>
        );
      },
    },
    {
      title: <img src={Edit} alt="Edit" className="max-w-6" />,
      dataIndex: "can_update",
      key: "can_update",
      width: 50,
      render: (record, rowData) => {
        return (
          <div
            onClick={() =>
              handlePermissionToggle("can_update", record, rowData?.module_id)
            }
          >
            <img
              src={record ? TickIcon : BlockIcon}
              alt=""
              className="max-w-6"
            />
          </div>
        );
      },
    },
    {
      title: <img src={DeleteIcon} alt="delete" className="max-w-6" />,
      dataIndex: "can_delete",
      key: "can_delete",
      width: 50,
      render: (record, rowData) => {
        return (
          <div
            onClick={() =>
              handlePermissionToggle("can_delete", record, rowData?.module_id)
            }
          >
            <img
              src={record ? TickIcon : BlockIcon}
              alt=""
              className="max-w-6"
            />
          </div>
        );
      },
    },
    {
      title: <BiSelectMultiple size={20} color="#88909A" />,
      dataIndex: "can_select",
      key: "can_select",
      width: 50,
      render: (record, rowData) => {
        return (
          <div
            onClick={() =>
              handlePermissionToggle("can_select", record, rowData?.module_id)
            }
          >
            <img
              src={record ? TickIcon : BlockIcon}
              alt=""
              className="max-w-6"
            />
          </div>
        );
      },
    },
  ];

  const handlePermissionToggle = (value, record, id) => {
    setRoleData((prev) => {
      return {
        ...prev,
        permissions: prev.permissions.map((p) =>
          p.module_id === id ? { ...p, [value]: !record } : p,
        ),
      };
    });
  };

  const handlePermissionsAdd = async () => {
    try {
      const resp = await axiosInstance.put(Apis.UPDATE_A_ROLE(roleId), {
        name: roleData.role.name,
        permissions: roleData.permissions,
      });
      if (resp.status === 200) {
        notification.success({ message: t("permissions_update_success") });
        navigate("/settings/toegangscontrole");
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  return (
    <Flex className="w-full">
      <div className="px-4 xl:px-16 w-full">
        <div className="flex flex-wrap md:items-center md:justify-between mt-9 mb-11">
          <div
            onClick={() => {
              navigate("/settings/toegangscontrole");
              dispatch(
                setActiveTab({
                  page: "settings",
                  tab: t("access_control"),
                  redirectionLink: "/settings/toegangscontrole",
                }),
              );
            }}
          >
            <LeftSideTitlePart
              pageTitle={roleData?.role?.name}
              pageSubTitle={t("access_control")}
              backButton={true}
              // backLink="/toegangscontrole"
            />
          </div>
          <RightSIdeTitlePart
            archiveButton={true}
            deleteButton={true}
            showButton={true}
            handleShowClick={handlePermissionsAdd}
          />
        </div>

        <section className="container max-w-4xl mt-9">
          <Title level={2} className="font-bold !text-2xl">
            {t("permissions")}
            <span className="font-normal text-[--notication-status-active] opacity-60 text-sm ml-2">
              {t("saves_automatically")}
            </span>
          </Title>
          <TableStripe
            columns={columns}
            dataSource={roleData?.permissions}
            rowKey={(record) => record?.module_id}
            pagination={false}
            scroll={{ x: 300 }}
          />
          <SystemCollectionButtonStyle className="system-collection-button">
            {t("system_collections")} {<IoIosArrowDown />}
          </SystemCollectionButtonStyle>
          {/* <Form layout="vertical" className="mt-12">
            <Row gutter={28}>
              <Col className="gutter-row" xs={24} md={12}>
                <Form.Item
                  label={t("role") + " " + t("name")}
                  className="font-bold"
                >
                  <Input
                    placeholder={t("editor")}
                    className="min-h-14 rounded-xl placeholder:font-normal px-6"
                  />
                </Form.Item>
              </Col>
              <Col className="gutter-row" xs={24} md={12}>
                <Form.Item
                  label={t("role") + " " + t("icon").toLowerCase()}
                  className="font-bold"
                >
                  <Input
                    prefix={
                      <img
                        src={Checkbook}
                        alt=""
                        className="w-6 h-6 object-contain"
                      />
                    }
                    onChange={clearText}
                    allowClear={true}
                    placeholder="Checkbook"
                    className="min-h-14 rounded-xl placeholder:font-normal px-6"
                  />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={24}>
                <Form.Item label={t("description")} className="font-bold">
                  <Input
                    placeholder={t("editor")}
                    className="min-h-14 rounded-xl placeholder:font-normal px-6"
                  />
                </Form.Item>
              </Col>
              <Col className="gutter-row" xs={24} md={12}>
                <Form.Item
                  label={t("app") + " " + t("access").toLowerCase()}
                  className="font-bold"
                >
                  <Checkbox checked className=" rounded-xl font-normal">
                    {t("enabled")}
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col className="gutter-row" xs={24} md={12}>
                <Form.Item
                  label={t("admin") + " " + t("access").toLowerCase()}
                  className="font-bold"
                >
                  <Checkbox className=" rounded-xl font-normal">
                    {t("enabled")}
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={24}>
                <Form.Item
                  label={t("ip") + " " + t("access").toLowerCase()}
                  className="font-bold"
                >
                  <Input
                    placeholder={t("ip_msg")}
                    className="min-h-14 rounded-xl placeholder:font-normal px-6"
                  />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={24}>
                <Form.Item
                  label={
                    t("two") +
                    " " +
                    t("factor") +
                    " " +
                    t("authentication") +
                    " " +
                    t("required").toLowerCase()
                  }
                  className="font-bold"
                >
                  <Checkbox className=" rounded-xl font-normal">
                    {t("2fa_required")}
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={24}>
                <Form.Item
                  label={t("users_with_this_role")}
                  className="font-bold"
                >
                  <Select
                    mode="multiple"
                    size="large"
                    placeholder="Please select"
                    // onChange={handleChange}
                    style={{
                      width: "100%",
                    }}
                    options={tags}
                  />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={24}>
                <Form.Item className="font-bold">
                  <Button className="rounded-3xl bg-btn-dark text-white px-5 font-medium h-auto py-3">
                    {t("add_new_user")}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form> */}
        </section>
      </div>
      <AppInfo />
    </Flex>
  );
};

export default Permissions;
