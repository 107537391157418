import React from "react";
import { Button, Menu, Space } from "antd";
import { FiPlus } from "react-icons/fi";
import styled from "styled-components";
import { convertToTreeData } from "../helpers/utils";
import { useTranslation } from "react-i18next";

const ColumnsListBtn = styled(Space)`
  position: absolute;
  top: 0px;
  right: 0;
  z-index: 1;
  gap: 0;
  .columnsList-btn {
    width: 56px;
    height: 56px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    border-radius: 0 10px 0 0;
  }
`;

const SelectTableColumns = ({
  selectedColumns,
  setSelectedColumns,
  columnOptions,
  handleColumnsAdd,
  selectOpen,
  setSelectOpen,
}) => {
  const { t } = useTranslation();
  // const handleClickOutside = (event) => {
  //   if (
  //     selectOpen &&
  //     !event.target.closest(".tree-select-container") &&
  //     !event.target.closest(".columnsList-btn")
  //   ) {
  //     setSelectOpen(false);
  //   }
  // };

  // useEffect(() => {
  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [selectOpen]);

  const items = convertToTreeData(columnOptions, "tree-columns");

  const findSelectedNode = (key, items) => {
    for (let item of items) {
      if (item.key === key) {
        return item;
      }
      if (item.children) {
        const found = findSelectedNode(key, item.children);
        if (found) return found;
      }
    }
    return null;
  };

  const updateSelectedColumns = (key, isSelected) => {
    const selectedNode = findSelectedNode(key, items);
    setSelectedColumns((prev) =>
      isSelected
        ? [
            ...prev,
            {
              display_key:
                selectedNode?.value.replaceAll(".", "_") + selectedNode?._id,
              value: selectedNode?.value,
              fields: selectedNode?.fields,
            },
          ]
        : prev.filter((item) => item?.value !== selectedNode?.value),
    );
  };

  return (
    <ColumnsListBtn>
      <div
        className="columnsList-btn"
        onClick={() => setSelectOpen((prev) => !prev)}
      >
        <FiPlus />
      </div>
      {selectOpen && (
        <div className="add-field-type-dropdown-wrapper">
          <div className="add-field-type-dropdown custom-scrollbar">
            <Menu
              items={items}
              selectedKeys={selectedColumns?.map((col) => col?.value)}
              multiple
              mode="inline"
              onSelect={(a1) => updateSelectedColumns(a1.key, true)}
              onDeselect={(a1) => updateSelectedColumns(a1.key, false)}
            />
          </div>
          <Button className="add-column-btn" onClick={handleColumnsAdd}>
            {t("update_columns")}
          </Button>
        </div>
      )}
    </ColumnsListBtn>
  );
};

export default SelectTableColumns;
