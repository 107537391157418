import { useQuery } from "@tanstack/react-query";
import axiosInstance from "../axios";
import Apis from "../Apis";
import { store } from "../../Redux/store";

const useGroupedTranslations = (lng) => {
  const fetchGroupedTranslations = async () => {
    if (store.getState().user?.userProfile?.is_tenant_owner) {
      const { data } = await axiosInstance.get(
        Apis.GET_TRANSLATIONS_BY_GROUP(lng),
      );
      return data?.payload?.data[0] || {};
    } else {
      return {};
    }
  };

  const { data } = useQuery({
    queryKey: ["groupedTranslations", lng],
    queryFn: fetchGroupedTranslations,
    enabled: !!lng,
    refetchOnWindowFocus: false,
    staleTime: 24 * 60 * 60 * 1000,
    cacheTime: 24 * 60 * 60 * 1000,
  });
  return data || {};
};

const useSystemTranslations = () => {
  const fetchGroupedTranslations = async () => {
    const { data } = await axiosInstance.get(Apis.GET_SYSTEM_TRANSLATIONS());
    return data?.payload?.data || {};
  };

  const { data } = useQuery({
    queryKey: ["systemTranslations"],
    queryFn: fetchGroupedTranslations,
    refetchOnWindowFocus: false,
    staleTime: 24 * 60 * 60 * 1000,
    cacheTime: 24 * 60 * 60 * 1000,
  });
  return data || {};
};

export { useSystemTranslations, useGroupedTranslations };
