import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import axiosInstance from "../axios";
import Apis from "../Apis";
import { store } from "../../Redux/store";
import { setLanguageOptions } from "../../Redux/slices/language.reducer";

const useLanguage = () => {
  const dispatch = useDispatch();
  const storedOptions = store.getState()?.languageOptions;
  const [options, setOptions] = useState(storedOptions);

  useEffect(() => {
    const fetchLanguageOptions = async () => {
      try {
        const resp = await axiosInstance.get(Apis.GET_LANGUAGE_OPTIONS());
        if (resp.status === 200) {
          const options = resp.data?.payload?.data?.map((t) => {
            return { label: t?.name, value: t?.code };
          });
          dispatch(setLanguageOptions(options));
          setOptions(options);
        }
      } catch (error) {
        console.log("Error fetching translation options:", error);
      }
    };

    if (!storedOptions?.length) {
      fetchLanguageOptions();
    }
  }, []);

  return options;
};

export default useLanguage;
