import React, { useEffect, useState } from "react";
import { Button } from "antd";
import { IoClose } from "react-icons/io5";
import EditFiles from "./EditFiles";
import { getDisplayImage } from "../../../helpers/utils";
import { DrawerStyle } from "../../../Common/CommonUiStyles";
import { RiShareBoxLine } from "react-icons/ri";
import notFoundImage from "../../../Assets/images/file-not-found.svg";

const FilesList = ({ file, iFace, handleDelete, setFiles }) => {
  const [imageUrl, setImageUrl] = useState(null);
  const [showEditFile, setShowEditFile] = useState(false);

  useEffect(() => {
    assignImageUrl();
  }, [file?._id]);

  const assignImageUrl = async () => {
    setImageUrl(
      ["image/jpg", "image/jpeg", "image/png"].includes(file?.type)
        ? await getDisplayImage(file?._id)
        : null,
    );
  };

  return (
    <>
      {iFace === "file-image" ? (
        <div key={file?._id} className="image-add-list">
          <img
            src={imageUrl ? imageUrl : notFoundImage}
            alt="Image"
            className="added-image"
          />
          <Button
            className="p-0 border-0 file-image-buttons image-add-button"
            onClick={() => handleDelete(file?._id)}
          >
            <IoClose size={24} />
          </Button>
          <Button
            className="p-0 border-0 file-image-buttons image-edit-button"
            onClick={(e) => {
              e.preventDefault();
              setShowEditFile(true);
            }}
          >
            <RiShareBoxLine size={24} />
          </Button>
        </div>
      ) : (
        <div
          key={file?._id}
          className="file-add-list"
          onClick={() => setShowEditFile(true)}
        >
          {imageUrl ? (
            <img src={imageUrl} alt="TEST" className="file-add-list-img" />
          ) : (
            <div className="file-add-list-file-extention">
              {file?.filename_disk?.split(".")?.pop()}
            </div>
          )}
          <div>{file?.title}</div>
          <Button
            className="p-0 border-0 bg-[transparent] hover:bg-[transparent] hover:!text-[rgba(var(--btn-delete),1)]"
            onClick={(e) => {
              e.stopPropagation();
              handleDelete(file?._id);
            }}
          >
            <IoClose
              size={24}
              className="hover:bg-[transparent] hover:!text-[rgba(var(--btn-delete),1)]"
            />
          </Button>
        </div>
      )}
      <DrawerStyle
        width={991}
        onClose={() => setShowEditFile(false)}
        open={showEditFile}
        destroyOnClose
      >
        <EditFiles
          setShowEditFile={setShowEditFile}
          fileId={file?._id}
          setFiles={setFiles}
          assignImageUrl={assignImageUrl}
          isDrawer
        />
      </DrawerStyle>
    </>
  );
};

export default FilesList;
