import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Flex,
  Input,
  Modal,
  notification,
  Pagination,
  Select,
  Skeleton,
  Spin,
} from "antd";
import SubMenu from "../../Components/SubMenu";
import { useTranslation } from "react-i18next";
import AppInfo from "../../Components/AppInfo";
import LeftSideTitlePart from "../../Components/HeaderParts/LeftSideTitlePart";
import IconsAll from "../../Components/IconsAll";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { themes } from "../../config";
import Paragraph from "antd/es/typography/Paragraph";
import {
  MdDeleteOutline,
  MdOutlineCreateNewFolder,
  MdOutlineDriveFileMove,
  MdOutlineEdit,
} from "react-icons/md";
import { FaRegFolder } from "react-icons/fa";
import axiosInstance from "../../helpers/axios";
import Apis from "../../helpers/Apis";
import { Link, useNavigate, useParams } from "react-router-dom";
import CustomDragger from "../../Components/DynamicForm/Components/CustomDragger";
import RightSIdeTitlePart from "../../Components/HeaderParts/RightSIdeTitlePart";
import {
  setDestinationFolderData,
  setFolderActionData,
  setIsMoveFolderModalOpen,
  setSelectedFolderData,
} from "../../Redux/slices/media.reducer";
import { getDisplayImage } from "../../helpers/utils";
import EditFiles from "../../Components/DynamicForm/Components/EditFiles";
import { GoSortAsc, GoSortDesc } from "react-icons/go";
import FileExtension from "../../Components/DynamicForm/Components/FileExtension";
import NoData from "../../Components/NoData";
import { usePagination } from "../../helpers/custom-hooks/usePagination";

const GebruikerSort = styled(Flex)`
  border-radius: 8px;
  border: 1px solid hsla(var(--input-field-border-color), 0.25);
  background: var(--gebruikers-table-sortBg);
  box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.04);
  backdrop-filter: blur(2px);
  position: sticky;
  top: 10px;
  z-index: 1;
  padding: 13px 24px;
  align-items: center;
  justify-content: space-between;

  .ant-checkbox-wrapper-disabled {
    opacity: 0.5;
  }

  .ant-checkbox {
    .ant-checkbox-inner {
      border-radius: 100vh;
      border: 1px solid #88909a;
    }

    + span {
      color: #88909a;
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
    }
  }

  .email-sort-button {
    &:hover,
    &:active,
    &:focus-visible {
      background-color: transparent !important;
    }
  }
`;

const DocumentCard = styled(Card)`
  border: none;
  max-width: 160px;
  position: relative;
  background-color: transparent;

  &:hover {
    .dock-checkbox {
      opacity: 1;
    }
  }

  .dock-checkbox {
    position: absolute;
    top: 5px;
    left: 5px;
    transition: 0.3s ease-in-out;
    z-index: 1;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
  }

  .ant-card-body {
    padding: 0;

    .doc-image {
      width: 160px;
      height: 160px;
      border-radius: 10px;
      margin-bottom: 5px;
      overflow: hidden;
      background-color: ${() =>
        themes[useSelector((state) => state.theme.name)].components.Menu
          .itemBg};
      display: flex;
      align-items: center;
      justify-content: center;

      > img {
        object-fit: cover;
        border-radius: 10px;
      }

      > svg {
        width: 50px;
        height: 50px;
        object-fit: contain;
      }
    }

    .folder-info {
      list-style: none;
      margin-bottom: 0;
      display: flex;
      font-size: 14px;
      gap: 5px;

      li {
        margin: 0;
        padding: 0;
      }
    }
  }

  &:has(.ant-checkbox-checked) {
    .doc-image {
      > img {
        transform: scale(0.9);
      }
    }
  }
`;

const FileCard = ({
  file,
  selectedFiles,
  setSelectedFiles,
  allowMultiple,
  iFace,
}) => {
  const [imageUrl, setImageUrl] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    assignImageUrl();
  }, [file?._id]);

  const assignImageUrl = async () => {
    setImageUrl(
      ["image/jpg", "image/jpeg", "image/png"].includes(file?.type)
        ? await getDisplayImage(file?._id, 160, 160)
        : null,
    );
  };

  return (
    <DocumentCard key={file?._id}>
      <Checkbox
        onChange={(e) =>
          setSelectedFiles((prev) =>
            allowMultiple
              ? e.target.checked
                ? [...prev, file?._id]
                : prev?.filter((p) => p !== file?._id)
              : [file?._id],
          )
        }
        checked={selectedFiles?.includes(file?._id)}
        className={`dock-checkbox ${
          !selectedFiles?.length ? "opacity-0" : "opacity-100"
        }`}
      ></Checkbox>
      <div
        className="doc-image"
        onClick={() =>
          !iFace && navigate(`/media-library/file/${file?._id}/edit`)
        }
      >
        {imageUrl ? (
          <img src={imageUrl} alt="image" />
        ) : (
          <FileExtension
            size={90}
            extension={file?.filename_disk?.split(".")?.pop()}
          />
        )}
        {/* <IconsAll.AdministratorIcon /> */}
      </div>
      <Paragraph
        className="!mb-1 font-bold whitespace-nowrap text-ellipsis overflow-hidden"
        title={file?.title}
      >
        {file?.title}
      </Paragraph>
      <Paragraph>
        <ul className="folder-info">
          <li>{file?.filename_disk?.split(".")?.pop()}</li>
          <li>.</li>
          <li>{file?.file_size} kB</li>
        </ul>
      </Paragraph>
    </DocumentCard>
  );
};
const Media = ({ updateContentFilesList, setShowDrawer, iFace }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    selectedFolderData,
    isMoveFolderModalOpen,
    folderActionData,
    destinationFolderData,
  } = useSelector((state) => state.media);
  const { folderId, fileId } = useParams();

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [subMenus, setSubMenus] = useState([]);
  const [newFolderName, setNewFolderName] = useState("");
  const [isFileModalOpen, setIsFileModalOpen] = useState(false);
  const [filesList, setFilesList] = useState([]);
  const [fileListLoading, setFileListLoading] = useState(false);
  const [folderModal, setFolderModal] = useState({
    type: t("create"),
    isOpen: false,
    loading: false,
  });
  const [openFolders, setOpenFolders] = useState([]);
  const sortOptions = [
    {
      value: "title",
      label: t("title"),
    },
    {
      value: "file_size",
      label: t("size"),
    },
    {
      value: "createdAt",
      label: t("uploaded_on"),
    },
  ];

  const { pagination, updatePagination } = usePagination(
    1,
    25,
    "createdAt",
    true,
  );

  useEffect(() => {
    getFoldersList();
  }, []);

  useEffect(() => {
    getFilesList();
  }, [
    selectedFolderData?._id,
    pagination.currentPage,
    pagination.pageSize,
    pagination.sortKey,
    pagination.isSortDesc,
  ]);

  const getFilesList = async () => {
    try {
      setFileListLoading(true);
      const resp = await axiosInstance.get(
        Apis.GET_FILES({
          folder: selectedFolderData?.isRoot
            ? ""
            : selectedFolderData?._id || "",
          page: pagination.currentPage,
          limit: pagination.pageSize,
          descending: pagination.isSortDesc,
          sortBy: pagination.sortKey,
        }),
      );
      if (resp.status === 200) {
        updatePagination(
          "totalItems",
          resp.data?.payload?.meta?.total_found || 0,
        );
        setFilesList(resp.data?.payload?.data);
      }
    } catch (error) {
      console.log("Error", error);
    } finally {
      setFileListLoading(false);
    }
  };

  const updateFilesList = () => {
    getFilesList();
  };

  const moveFolder = async () => {
    try {
      if (
        !(
          destinationFolderData?._id &&
          destinationFolderData?._id !== folderActionData?._id
        )
      )
        return;
      await axiosInstance.put(Apis.MOVE_FOLDER(), {
        sourceFolder: folderActionData?._id,
        destinationFolder: destinationFolderData?._id,
      });
      notification.success({
        message: t("folder_move_success", {
          folder: folderActionData?.name,
          destinationFolder: destinationFolderData?.name,
        }),
      });
      getFoldersList();
    } catch (error) {
      notification.error({
        message: t("folder_move_error", {
          folder: folderActionData?.name,
          destinationFolder: destinationFolderData?.name,
        }),
      });
      console.log("Error", error);
    } finally {
      dispatch(setIsMoveFolderModalOpen(false));
      dispatch(setFolderActionData({}));
      dispatch(setDestinationFolderData({}));
    }
  };

  const CustomDropdown = ({ title, indentation = 0, link, folderData }) => {
    const dispatch = useDispatch();
    const {
      selectedFolderData,
      isMoveFolderModalOpen,
      folderActionData,
      destinationFolderData,
    } = useSelector((state) => state.media);

    const [dropDownVisible, setIsDropdownVisible] = useState(false);
    const dropDownPadding = 20;
    const dropdownRef = useRef(null);
    const [dropdownListClient, setDropdownListClient] = useState({
      X: 0,
      Y: 0,
    });

    const handleRightClick = (event) => {
      if (
        !window.location.pathname.includes("media-library") ||
        dropDownVisible ||
        isMoveFolderModalOpen ||
        folderData?.isRoot
      )
        return;
      event.preventDefault();
      setIsDropdownVisible(true);
      setDropdownListClient({ X: event.clientX + 10, Y: event.clientY - 10 });
    };

    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownVisible(false);
      }
    };

    const selectCurrentFolder = (folderData) => {
      if (isMoveFolderModalOpen) {
        if (folderData._id !== folderActionData?._id) {
          dispatch(setDestinationFolderData(folderData));
        }
        return;
      }
      dispatch(setSelectedFolderData(folderData));
    };

    useEffect(() => {
      if (dropDownVisible) {
        document.addEventListener("mousedown", handleClickOutside);
      } else {
        document.removeEventListener("mousedown", handleClickOutside);
      }

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [dropDownVisible]);

    return (
      <div
        className={`flex items-center justify-between ${
          folderData._id === folderActionData?._id
            ? "disabled-menu"
            : folderData._id === destinationFolderData?._id
              ? "custom-destination-folder"
              : folderData._id === selectedFolderData?._id
                ? "custom-selected-submenu"
                : ""
        }`}
        onContextMenu={handleRightClick}
      >
        <div
          className="edit-delete-wrapper overflow-hidden"
          ref={dropdownRef}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Link
            to={!iFace && !isMoveFolderModalOpen ? link : ""}
            className="block"
            onClick={() => {
              selectCurrentFolder(folderData);
            }}
            style={{ paddingLeft: dropDownPadding * indentation + "px" }}
          >
            <div className="flex items-center gap-3 fs-14 w-[90%]">
              {folderData?.isRoot ? (
                <MdOutlineCreateNewFolder size={24} />
              ) : (
                <FaRegFolder size={24} className="flex-shrink-0" />
              )}
              <span className="overflow-ellipsis overflow-hidden">{title}</span>
            </div>
          </Link>
          {dropDownVisible && (
            <ul
              style={{ left: dropdownListClient.X, top: dropdownListClient.Y }}
              className="edit-delete-dropdown"
            >
              <li
                onClick={() => {
                  setFolderModal({
                    type: t("rename"),
                    isOpen: true,
                    loading: false,
                  });
                  setNewFolderName(folderData?.name);
                  dispatch(setFolderActionData(folderData));
                }}
                className="flex items-center gap-2"
              >
                <MdOutlineEdit size={20} className="flex-shrink-0" />
                {t("rename")}
              </li>
              <li
                onClick={() => {
                  dispatch(setFolderActionData(folderData));
                  dispatch(setIsMoveFolderModalOpen(true));
                }}
                className="flex items-center gap-2"
              >
                <MdOutlineDriveFileMove size={20} className="flex-shrink-0" />
                {t("move")}
              </li>
              <li
                className="flex items-center gap-2 !text-[rgba(var(--btn-delete))]"
                onClick={() => {
                  dispatch(setFolderActionData(folderData));
                  setFolderModal({
                    type: t("delete"),
                    isOpen: true,
                    loading: false,
                  });
                }}
              >
                <MdDeleteOutline
                  size={20}
                  className="flex-shrink-0 !text-[rgba(var(--btn-delete))]"
                />
                {t("delete")}
              </li>
            </ul>
          )}
        </div>
        <div className="w-[45px]"></div>
      </div>
    );
  };

  const getFoldersList = async () => {
    try {
      let response = await axiosInstance.get(
        Apis.GET_ALL_FOLDERS({ pagination: "false" }),
      );
      setSubMenus(transformData(response?.data?.payload?.data));
    } catch (error) {
      console.log("Error", error);
    }
  };

  const openkeysCalculation = (folders = [], initialKey) => {
    let parentFolderId = initialKey;
    const parentIds = [];
    while (parentFolderId) {
      const currentFolderData = folders.find((item) => {
        return item._id === parentFolderId;
      });
      if (currentFolderData?._id) {
        parentIds.push(currentFolderData._id);
      }
      parentFolderId = currentFolderData?.parent;
    }
    setOpenFolders([...parentIds]);
  };

  const transformData = (folders) => {
    let matchedFolderData = {};

    const buildAndTransformHierarchy = (parentId, indentation = 1) => {
      return folders
        .filter((folder) => folder.parent === parentId)
        .map((folder) => {
          if (folder._id === folderId) {
            matchedFolderData = folder;
          }
          folder.isRoot = folder.parent === null;

          const children = buildAndTransformHierarchy(
            folder._id,
            indentation + 1,
          );

          const transformedItem = {
            key: folder._id,
            indentation,
            ...(folder.isRoot
              ? {
                  name: (
                    <CustomDropdown
                      title={folder.name}
                      indentation={indentation}
                      link={"/media-library"}
                      folderData={folder}
                    />
                  ),
                }
              : {
                  label: (
                    <CustomDropdown
                      title={folder.name}
                      indentation={indentation}
                      link={"/media-library/" + folder._id}
                      folderData={folder}
                    />
                  ),
                }),
            children: children.length > 0 ? children : undefined,
          };

          if (
            folder.isRoot &&
            !folderId &&
            selectedFolderData?._id !== folder._id &&
            !matchedFolderData?._id
          ) {
            matchedFolderData = folder;
          }

          return transformedItem;
        });
    };

    const hierarchicalData = buildAndTransformHierarchy(null);

    if (matchedFolderData?._id) {
      if (selectedFolderData?._id !== matchedFolderData?._id) {
        dispatch(setSelectedFolderData(matchedFolderData));
      }
      openkeysCalculation(folders, matchedFolderData._id);
    } else if (folderId) {
      navigate("/media-library");
    }

    return hierarchicalData;
  };

  function updateItemByKey(data, targetKey, newData, action = "update") {
    function recursiveUpdate(item) {
      if (item.key === targetKey) {
        if (action === "delete") {
          return null;
        }
        if (React.isValidElement(item.label)) {
          const updatedLabelProps = {
            ...JSON.parse(JSON.stringify(item?.label?.props || {})),
          };

          for (const key in newData) {
            if (key.startsWith("label.props.")) {
              const propPath = key.replace("label.props.", "").split(".");
              let current = updatedLabelProps;

              for (let i = 0; i < propPath.length - 1; i++) {
                const part = propPath[i];
                if (!current[part]) {
                  current[part] = {};
                }
                current = current[part];
              }

              current[propPath[propPath.length - 1]] = newData[key];
            }
          }

          dispatch(setSelectedFolderData(updatedLabelProps.folderData));

          return {
            ...item,
            label: React.cloneElement(item.label, updatedLabelProps),
          };
        }

        return { ...item, ...newData };
      }

      if (action === "create" && item.key === newData.parent) {
        // Add newly created folder to the parent folder
        let children = [
          ...(item?.children || []),
          {
            key: newData._id,
            indentation: item.indentation + 1,
            label: (
              <CustomDropdown
                title={newData.name}
                indentation={item.indentation + 1}
                link={"/media-library/" + newData._id}
                folderData={newData}
              />
            ),
          },
        ];

        if (!openFolders.includes(item.key)) {
          setOpenFolders([...openFolders, item.key]);
        }

        return {
          ...item,
          children,
        };
      }

      // If the item has children, apply the recursive update on them
      if (item.children && item.children.length > 0) {
        let children = [];
        // Apply the recursive update on the children
        item.children.map((child) => {
          const updatedChild = recursiveUpdate(child);
          if (action === "delete" && updatedChild === null) {
            dispatch(
              setSelectedFolderData({
                ...(item?.label?.props?.folderData || {}),
              }),
            );
          }
          updatedChild && children.push(updatedChild);
        });

        return {
          ...item,
          children,
        };
      }

      return item;
    }

    // Apply the recursive update starting from the root data
    return recursiveUpdate(data);
  }

  const handleFoldarModalOk = async (modalType) => {
    try {
      let folderName = "";
      let apiCall;
      setFolderModal({ type: modalType, isOpen: true, loading: true });

      switch (modalType) {
        case "Create":
          folderName = newFolderName.trim();
          if (!folderName.length) return;
          apiCall = axiosInstance
            .post(Apis.CREATE_FOLDER(), {
              name: folderName,
              parent: selectedFolderData?._id || null,
            })
            .then((resp) => {
              setSubMenus((prevData) => [
                updateItemByKey(
                  prevData?.[0],
                  resp.data.payload.data._id,
                  resp.data.payload.data,
                  "create",
                ),
              ]);
              return resp;
            });
          break;

        case "Rename":
          folderName = newFolderName.trim();
          if (!folderName.length || folderName === folderActionData?.name)
            return;
          apiCall = axiosInstance
            .put(Apis.UPDATE_FOLDER(folderActionData._id), { name: folderName })
            .then((resp) => {
              setSubMenus((prevData) => [
                updateItemByKey(prevData?.[0], folderActionData._id, {
                  ["label.props.title"]: folderName,
                  ["label.props.folderData.name"]: folderName,
                }),
              ]);
              return resp;
            });
          break;

        case "Delete":
          apiCall = axiosInstance
            .delete(Apis.DELETE_FOLDER(folderActionData._id))
            .then((resp) => {
              setSubMenus((prevData) => [
                updateItemByKey(
                  prevData?.[0],
                  folderActionData._id,
                  {},
                  "delete",
                ),
              ]);
              return resp;
            });
          break;
        default:
          throw new Error(`Unknown action type: ${modalType}`);
      }
      const response = await apiCall;
      if (response.status === 200) {
        notification.success({ message: t("folder_success", { modalType }) });
      }
    } catch (error) {
      console.error(`Error during folder ${modalType}:`, error);
    } finally {
      folderActionData?._id && dispatch(setFolderActionData({}));
      setNewFolderName("");
      setFolderModal({ type: modalType, isOpen: false, loading: false });
    }
  };

  const handleFilesDelete = async () => {
    Modal.confirm({
      title: t("delete_files"),
      content: t("delete_files_confirm"),
      icon: null,
      okText: t("delete"),
      okType: "primary",
      cancelText: t("cancel"),
      maskClosable: true,
      // className: "modal-delete",
      onOk: async () => {
        try {
          const resp = await axiosInstance.put(Apis.DELETE_FILE(), {
            ids: selectedFiles,
          });
          if (resp.status === 200) {
            notification.success({ message: t("files_delete_success") });
            getFilesList();
            setSelectedFiles([]);
          }
        } catch (error) {
          notification.error({ message: t("files_delete_error") });
        }
      },
    });
  };

  const handleSelect = () => {
    updateContentFilesList(
      filesList?.filter((file) => selectedFiles?.includes(file?._id)),
    );
    setShowDrawer(false);
  };

  const onOpenChange = (keys) => {
    setOpenFolders(keys);
  };

  return (
    <Flex className="w-full main-content-wrap">
      {subMenus.length > 0 ? (
        <SubMenu
          isSubMenuLogo={true}
          isSubMenuVersion={true}
          items={subMenus}
          openKeys={openFolders}
          currentPage={"media"}
          onOpenChange={onOpenChange}
        />
      ) : (
        <div className="w-[260px] sticky top-0 bottom-0 z-50 lg:min-h-screen modal-sidebar-media p-8">
          <Skeleton></Skeleton>
        </div>
      )}
      {fileId ? (
        <EditFiles fileId={fileId} />
      ) : (
        <div id="left-part" className="px-4 xl:px-16 w-full left-part">
          <div className="flex flex-wrap md:items-center justify-between mt-9 mb-11">
            <LeftSideTitlePart
              pageIcon={true}
              pageInnerIcon={
                <IconsAll.MediaLibraryIcon strokeColor={"#3586c7"} />
              }
              pageTitle={selectedFolderData?.name || t("file_library")}
              pageSubTitle={t("file_library")}
            />
            <RightSIdeTitlePart
              addButton={!iFace}
              handleAddClick={() => setIsFileModalOpen(true)}
              deleteButton={selectedFiles?.length > 0 && !iFace}
              deleteDisabled={!selectedFiles?.length}
              handleDeleteClick={handleFilesDelete}
              folderButton={!iFace}
              showButton={iFace}
              handleShowClick={handleSelect}
              handleFolderClick={() => {
                setFolderModal({
                  type: t("create"),
                  isOpen: true,
                  loading: false,
                });
                setNewFolderName("");
              }}
            />
            {/* <===================== File Modal =====================> */}
            <Modal
              title={t("add_file")}
              open={isFileModalOpen}
              okText={t("ok")}
              cancelText={t("cancel")}
              onOk={() => setIsFileModalOpen(false)}
              onCancel={() => setIsFileModalOpen(false)}
              maskClosable={false}
              destroyOnClose
            >
              <CustomDragger
                iFace={`files-page`}
                setIsModalOpen={setIsFileModalOpen}
                updateFilesList={updateFilesList}
                folder={selectedFolderData?._id}
              />
            </Modal>
            {/* <===================== Move Folder Modal =====================> */}
            <Modal
              title={t("move_folder")}
              open={isMoveFolderModalOpen}
              okText={t("ok")}
              cancelText={t("cancel")}
              onOk={() => {
                moveFolder();
              }}
              onCancel={() => {
                dispatch(setIsMoveFolderModalOpen(false));
                dispatch(setDestinationFolderData({}));
              }}
              className="move-folder-modal"
              destroyOnClose={true}
              okButtonProps={{
                disabled: !(
                  destinationFolderData?._id &&
                  destinationFolderData?._id !== folderActionData?._id
                ),
              }}
            >
              <SubMenu
                isSubMenuLogo={false}
                isSubMenuVersion={false}
                items={subMenus}
                from={"media"}
                defaultOpenKeys={openFolders}
              />
            </Modal>
            {/* <===================== Commmon Folder Modal =====================> */}
            <Modal
              title={folderModal.type + " " + t("folder")}
              open={folderModal.isOpen}
              okText={t("ok")}
              cancelText={t("cancel")}
              onOk={() => handleFoldarModalOk(folderModal.type)}
              onCancel={() => {
                setFolderModal({
                  type: folderModal.type,
                  isOpen: false,
                  loading: false,
                });
                folderActionData?._id && dispatch(setFolderActionData({}));
              }}
              loading={folderModal.loading}
              destroyOnClose
            >
              <p className="mb-3">
                {folderModal.type === "Delete" ? (
                  <div>
                    {t("delete_folder_confirm", {
                      selectedFolder: selectedFolderData?.name,
                    })}
                  </div>
                ) : (
                  <Input
                    placeholder={t("name")}
                    className="min-h-14 rounded-xl placeholder:font-normal px-6"
                    value={newFolderName}
                    onChange={(e) => setNewFolderName(e.target.value)}
                  />
                )}
              </p>
            </Modal>
          </div>
          {fileListLoading ? (
            <Flex
              className="items-center justify-center"
              style={{ minHeight: "calc(100% - 350px)" }}
            >
              <Spin />
            </Flex>
          ) : filesList?.length ? (
            <>
              <GebruikerSort className="mb-8">
                <Checkbox
                  onChange={(e) =>
                    setSelectedFiles(
                      e.target.checked ? filesList?.map((f) => f?._id) : [],
                    )
                  }
                  disabled={["file", "file-image"].includes(iFace)}
                  checked={
                    selectedFiles?.length > 0 &&
                    selectedFiles?.length === filesList?.length
                  }
                >
                  {t("select_all")}
                </Checkbox>
                <div className="flex items-center justify-between">
                  <div className="w-full min-w-[120px]">
                    <span className="block text-sm text-slate-400">
                      {t("sort_on")}:
                    </span>
                    <Select
                      options={sortOptions}
                      variant="borderless"
                      onChange={(value) => updatePagination("sortKey", value)}
                      value={pagination.sortKey}
                      size={"small"}
                      suffixIcon={false}
                      className="remove-select-inline-padding"
                      disabled={fileListLoading}
                    />
                  </div>
                  <Button
                    icon={
                      pagination.isSortDesc ? (
                        <GoSortDesc size={20} />
                      ) : (
                        <GoSortAsc size={20} />
                      )
                    }
                    onClick={() =>
                      updatePagination("isSortDesc", !pagination.isSortDesc)
                    }
                    disabled={fileListLoading}
                  ></Button>
                </div>
              </GebruikerSort>
              <Flex gap="middle" align="start" wrap="wrap">
                {filesList?.map((file) => {
                  return (
                    <FileCard
                      key={file?._id}
                      file={file}
                      selectedFiles={selectedFiles}
                      setSelectedFiles={setSelectedFiles}
                      allowMultiple={!["file", "file-image"].includes(iFace)}
                      iFace={iFace}
                    />
                  );
                })}
              </Flex>
            </>
          ) : (
            <NoData
              icon={<FaRegFolder size={24} />}
              title={t("no_files")}
              description={t("there_are_no_files_here")}
            />
          )}
          {filesList?.length > 0 && (
            <Pagination
              pageSize={pagination.pageSize}
              current={pagination.currentPage}
              total={pagination.totalItems}
              onChange={(page) => updatePagination("currentPage", page)}
              onShowSizeChange={(current, size) =>
                updatePagination("pageSize", size)
              }
              disabled={fileListLoading}
              className="py-8"
            />
          )}
        </div>
      )}
      <Col className="right-part">
        <AppInfo />
      </Col>
    </Flex>
  );
};

export default Media;
