import React, { useEffect, useId, useState } from "react";
import { Flex, Input, Button, Select, Space, notification } from "antd";
import LeftSideTitlePart from "../../HeaderParts/LeftSideTitlePart";
import IconsAll from "../../IconsAll";
import RightSIdeTitlePart from "../../HeaderParts/RightSIdeTitlePart";
import { IoLanguage } from "react-icons/io5";
import styled from "styled-components";
import { RiDeleteBin6Line } from "react-icons/ri";
import ValidationOptions from "./validationOptionsJson.json";
import Apis from "../../../helpers/Apis";
import axiosInstance from "../../../helpers/axios";
import { capitalizeAndFormat } from "../../../helpers/utils";
import { useTranslation } from "react-i18next";

const ValidationFieldsWrapper = styled(Space)`
  gap: 10px;
  margin-top: 0;
  margin-bottom: 10px;
  .condition-options-select {
    width: 150px;
    height: 34px;
    .ant-select-selector {
      border-radius: 100vh;
    }
    .ant-select-selection-item {
      line-height: 34px;
    }
    .ant-select-arrow {
      inset-inline-end: 14px;
    }
  }
  .condition-options-input {
    background: var(--input-field-bg) !important;
    border-radius: 100vh;
    box-shadow: unset !important;
  }
  &:hover {
    .delete-button {
      opacity: 1;
      &:hover {
        color: #ff0505ae !important;
      }
    }
  }
`;

const ValidationFieldsColumn = styled(Space)`
  background-color: var(--validation-field-bg);
  border: 1px solid var(--input-field-border);
  gap: 0;
  width: 100%;
  border-radius: 10px;
  padding: 25px 20px 15px;
  margin-top: 20px;
  overflow: auto;
  height: auto;
  max-height: calc(100vh - 270px);
`;

const UpdateValidations = ({
  activeItem,
  setIsDrawerOpen,
  dataSource,
  setActiveItem,
  init,
  isDrawerOpen,
}) => {
  const { t } = useTranslation();
  const id = useId();
  const [validation, setValidation] = useState([]);
  const [inputMsg, setInputMsg] = useState(null);
  const conditionsList = ValidationOptions[activeItem?.meta?.interface]
    ? Array.isArray(ValidationOptions[activeItem?.meta?.interface])
      ? ValidationOptions[activeItem?.meta?.interface]
      : ValidationOptions[activeItem?.meta?.interface][activeItem?.type]
    : [];
  const nonInputConditions = new Set([
    "isNull",
    "isNotNull",
    "isEmpty",
    "isNotEmpty",
  ]);

  useEffect(() => {
    isDrawerOpen
      ? (setValidation(
          activeItem?.meta?.validations?.rules?.map((r) => {
            return r[r?.rule]?.insensitive
              ? {
                  rule: r?.rule + "Insensitive",
                  [r?.rule + "Insensitive"]: r[r?.rule],
                }
              : r;
          }) || [],
        ),
        setInputMsg(activeItem?.meta?.validations?.validation_msg))
      : (setValidation([]), setInputMsg(null));
  }, [activeItem?._id]);

  const handleSelect = (e, ind) => {
    setValidation((prev) => {
      const updatedValidation = [...prev];
      updatedValidation[ind] = {
        rule: e,
        [e]: {
          ...(!nonInputConditions.has(e) && { value: null }),
          ...(e?.includes("Insensitive") && { insensitive: true }),
        },
      };
      return updatedValidation;
    });
  };

  const ValidationFields = (item, ind) => {
    // return item?.type === "filter" ? (
    return (
      <ValidationFieldsWrapper key={ind}>
        <span>{capitalizeAndFormat(activeItem?.field)}</span>
        <Select
          value={item?.rule}
          options={conditionsList}
          onChange={(e) => handleSelect(e, ind)}
          className="condition-options-select"
        />
        {!nonInputConditions.has(item?.rule) && (
          <Input
            value={
              (item?.rule === "isOneOf" || item?.rule === "IsNotOneOf"
                ? Array.isArray(item[item?.rule]?.value)
                  ? item[item?.rule]?.value.join(", ")
                  : item[item?.rule]?.value || ""
                : item[item?.rule]?.value) || ""
            }
            onChange={(e) => {
              const newValue = e.target.value;
              setValidation((prev) => {
                return prev?.map((item, i) =>
                  i === ind
                    ? {
                        ...item,
                        [item?.rule]: {
                          ...item[item?.rule],
                          value:
                            item?.rule === "isOneOf" ||
                            item?.rule === "IsNotOneOf"
                              ? newValue.split(",").map((str) => str.trim())
                              : newValue,
                        },
                      }
                    : item,
                );
              });
            }}
            className="condition-options-input"
          />
        )}
        <Button
          type="link"
          className="p-0 h-auto text-[#ff0505] text-base opacity-0 | delete-button"
          onClick={() =>
            setValidation((prev) => prev?.filter((item, i) => i !== ind))
          }
        >
          <RiDeleteBin6Line />
        </Button>
      </ValidationFieldsWrapper>
    );
    // ) : (
    //   <div
    //     key={ind}
    //     onClick={(e) => {
    //       e.preventDefault();
    //       setValidation((prev) => {
    //         return prev?.map((item, i) =>
    //           i === ind
    //             ? {
    //                 ...item,
    //                 condition: item?.condition === "AND" ? "OR" : "AND",
    //               }
    //             : item,
    //         );
    //       });
    //     }}
    //   >
    //     {item?.condition} -- {item?.condition === "AND" ? "All" : "Any"} of the
    //     following
    //   </div>
    // );
  };

  const handleSubmit = async () => {
    const formattedValidation = validation?.map((v) => {
      return v?.rule?.includes("Insensitive")
        ? {
            rule: v?.rule?.replace("Insensitive", ""),
            [v?.rule?.replace("Insensitive", "")]: v[v?.rule],
          }
        : v;
    });

    try {
      const finalValues = {
        ...activeItem,
        meta: {
          ...activeItem?.meta,
          validations: {
            rules: formattedValidation,
            validation_msg: inputMsg,
          },
        },
      };
      const { _id, ...payload } = finalValues;
      delete payload?.__v;

      const resp = await axiosInstance.put(Apis.UPDATE_A_FIELD(_id), payload);
      if (resp.status === 200) {
        notification.success({ message: t("field_update_success") });
        setIsDrawerOpen(false);
        setActiveItem(null);
        init();
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleAdd = () => {
    // if (type === "filter") {
    let keyName = conditionsList?.length ? conditionsList[0]?.value : "";
    setValidation((prevState) => [
      ...prevState,
      { rule: keyName, [keyName]: { value: null, insensitive: false } },
    ]);
    // } else {
    //   setValidation((prev) => {
    //     return [...prev, { condition: "AND", type }];
    //   });
    // }
  };

  return (
    <>
      <Flex wrap="wrap" align="center" justify="space-between">
        <LeftSideTitlePart
          pageTitle={
            capitalizeAndFormat(activeItem?.meta?.interface) +
            " " +
            capitalizeAndFormat(activeItem?.type) +
            " (" +
            capitalizeAndFormat(dataSource?.collection_name) +
            ")"
          }
          pageIcon={true}
          pageInnerIcon={<IconsAll.ContentIcon strokeColor={"#3586c7"} />}
        />
        <RightSIdeTitlePart showButton={true} handleShowClick={handleSubmit} />
      </Flex>
      <Flex vertical>
        <div className="mb-2">
          {validation?.length > 0 ? (
            <ValidationFieldsColumn direction="vertical">
              {validation?.map((item, i) => ValidationFields(item, i))}
            </ValidationFieldsColumn>
          ) : (
            <Input
              placeholder={t("no_configured_validations")}
              className="mt-4 h-12"
            />
          )}
        </div>
        <div className="mb-2">
          <Button
            type="link"
            className="px-0 font-medium h-auto"
            onClick={() => handleAdd("filter")}
          >
            {t("add_filter")}
          </Button>
        </div>
        <div className="mb-2">
          <label htmlFor={`${id}-val_msg`} className="mb-2 block">
            {t("custom_validation_message")}
          </label>
          <Input
            id={`${id}-val_msg`}
            onChange={(e) => setInputMsg(e?.target?.value || "")}
            className="h-12"
            value={inputMsg}
            suffix={<IoLanguage size={20} color="gray" />}
          />
        </div>
      </Flex>
    </>
  );
};

export default UpdateValidations;
