import React, { useEffect } from "react";
import "antd/dist/reset.css";
import "./Assets/scss/App.scss";
import "./i18n";
import RoutesComponents from "./Routes/index";
import { useSelector } from "react-redux";
import i18n from "./i18n";
import { useDispatch } from "react-redux";
import Apis from "./helpers/Apis";
import axiosInstance from "./helpers/axios";
import { setUserProfile } from "./Redux/slices/userDetails.reducer";
import { useLocation } from "react-router-dom";
import {
  useGroupedTranslations,
  useSystemTranslations,
} from "./helpers/custom-hooks/useGroupedTranslations";

const App = () => {
  const bodyTag = document.querySelector("body");
  const htmlTag = document.querySelector("html");
  const activeTheme = useSelector((state) => state.theme.name);
  bodyTag.setAttribute("data-theme", activeTheme);
  const lng = useSelector((state) => state?.user?.userProfile?.language);
  let location = useLocation();
  const dispatch = useDispatch();
  const translations = useGroupedTranslations(lng);
  const systemTranslations = useSystemTranslations();

  useEffect(() => {
    Object.keys(translations).forEach((code) => {
      if (translations[code]) {
        i18n.addResourceBundle(
          code,
          "tenant",
          translations[code] || {},
          true,
          true,
        );
      }
    });
    i18n.changeLanguage(lng || "en");
  }, [lng, translations]);

  useEffect(() => {
    Object.keys(systemTranslations).forEach((code) => {
      if (systemTranslations[code]) {
        i18n.addResourceBundle(
          code,
          "system",
          systemTranslations[code] || {},
          true,
          true,
        );
      }
    });
    i18n.changeLanguage(lng || "en");
  }, [systemTranslations]);

  useEffect(() => {
    htmlTag.className = activeTheme?.includes("dark") ? "dark" : "";
  }, [activeTheme, location]);

  useEffect(() => {
    getUserProfile();
  }, []);

  const getUserProfile = async () => {
    try {
      const resp = await axiosInstance.get(Apis.GET_USER_PROFILE());

      if (resp.status === 200) {
        document.title = resp.data.payload.data?.is_tenant_owner
          ? resp.data.payload.data?.tenant?.name + " | NOX®"
          : "Super Admin | NOX®";
        dispatch(setUserProfile(resp.data.payload.data));
      }
    } catch (error) {
      console.log("Error", error);
    }
  };
  return <RoutesComponents />;
};

export default App;
