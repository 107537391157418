import { Flex } from "antd";
import Paragraph from "antd/es/typography/Paragraph";
import Title from "antd/es/typography/Title";
import React from "react";

const NoData = ({ title, description, icon }) => {
  return (
    <Flex
      className="items-center justify-center"
      style={{ minHeight: "calc(100% - 250px)" }}
    >
      <Flex className="text-center" vertical>
        <div className="w-20 h-20 rounded-full bg-grey-500 mx-auto mb-4 flex items-center justify-center">
          {icon}
        </div>
        <Title className="!mb-0">{title}</Title>
        <Paragraph>{description}</Paragraph>
      </Flex>
    </Flex>
  );
};

export default NoData;
