import React from "react";
import { Table } from "antd";
import { styled } from "styled-components";
import { useTranslation } from "react-i18next";

const TableStripe = styled(Table)`
  .ant-table {
    background-color: ${({ theme }) => theme.components.Table.tableBg};

    .gray-clr {
      color: ${({ theme }) => theme.token.grayClr};
    }

    .ant-table-cell {
      &.ant-table-column-has-sorters {
        &:hover {
          background-color: ${({ theme }) =>
            theme.components.Table.sortedBg} !important;
        }
      }
      &.ant-table-column-sort {
        background-color: ${({ theme }) => theme.components.Table.sortedBg};
      }
    }

    .imgLocationClr {
      color: ${({ theme }) => theme.components.Table.imgLocationClr};
      margin-left: 10px;
    }

    .ant-table-thead {
      .ant-table-cell {
        border-bottom: 1px solid #899099;
      }
    }
    .ant-table-row:nth-child(odd) {
      background: var(--table-odd-row-bg);
    }
  }
`;

const TenantListTable = ({ tenants, setActiveTenant, setIsDrawerOpen }) => {
  const { t } = useTranslation();

  const columns = [
    {
      title: t("name"),
      dataIndex: "name",
      render: (record, rowData) => {
        return (
          <div
            onClick={() => {
              setActiveTenant(rowData), setIsDrawerOpen(true);
            }}
            style={{ cursor: "pointer" }}
          >
            {rowData?.name}
          </div>
        );
      },
    },
    {
      title: t("email"),
      dataIndex: "email",
      render: (record, rowData) => {
        return <div>{rowData?.userId?.email}</div>;
      },
    },
    // {
    // title: "Primary database connection string",
    // dataIndex: "primaryDatabase",
    // render: (record, rowData) => {
    //   return <div>{rowData?.primary_database_connection_string}</div>;
    // },
    // },
    {
      title: t("primary_domain"),
      dataIndex: "primaryDomain",
      render: (record, rowData) => {
        return <div>{rowData?.primary_domain}</div>;
      },
    },
    {
      title: t("cors_urls"),
      dataIndex: "corsUrls",
      render: (record, rowData) => {
        return <div>{rowData?.cors_urls}</div>;
      },
    },
  ];

  return (
    <TableStripe
      className="max-w-full overflow-auto"
      pagination={{
        position: ["bottomCenter"],
        pageSize: 10,
        defaultCurrent: 1,
        // total: 100,
      }}
      rowKey={(record) => record?._id}
      columns={columns}
      dataSource={tenants?.filter((t) => !!t.name)}
      /* scroll={{
        x: 700,
      }} */
    />
  );
};

export default TenantListTable;
