import { Checkbox, Drawer, Form, Flex, Typography, Table } from "antd";
import styled from "styled-components";
import { themes } from "../config";
import { useSelector } from "react-redux";

const DrawerStyle = styled(Drawer)`
  .ant-drawer-header {
    padding: 0;
    border: unset !important;
    .ant-drawer-header-title {
      .ant-drawer-close {
        width: 56px;
        height: 56px;
        position: absolute;
        left: -65px;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 30px;
        background: white;
        border-radius: 100vh;
        box-shadow: 0px 4px 12px 0px rgba(8, 24, 44, 0.16);
        @media screen and (max-width: 991px) {
          left: calc(50% - 28px);
          bottom: 1rem;
          top: auto;
        }
      }
    }
  }
  .ant-drawer-body {
    &:has(.ant-tabs-nav) {
      padding: 0;
    }
    .ant-tabs-nav {
      padding-top: 40px;
      width: min(100%, 200px);
      min-height: 100vh;
      background-color: #f5f6f7;
      .ant-tabs-nav-wrap {
        &:before,
        &:after,
        .ant-tabs-ink-bar {
          display: none;
        }
        .ant-tabs-nav-list {
          padding-inline: 10px;
          .ant-tabs-tab {
            border-radius: 8px;
            margin-top: 5px;
            > div {
              color: #000 !important;
              font-size: 16px;
              font-style: normal;
            }
            &.ant-tabs-tab-active {
              background-color: #ebeced;
            }
          }
        }
      }
    }

    .ant-tabs-content-holder {
      padding-top: 40px;
      .ant-tabs-tabpane {
        padding-right: 24px;
      }
    }
  }
`;

const FormStyling = styled(Form)`
  background-color: var(--form-input-wrapper);
  border-top: 1px solid var(--input-field-border);
  border-bottom: 1px solid var(--input-field-border);
  padding: 34px;
`;

const InvertedCheckBox = styled(Checkbox)`
  border-radius: 12px;
  border: 1px solid
    ${() =>
      themes[useSelector((state) => state.theme.name)].components.Input
        .colorBorder};
  background: ${() =>
    themes[useSelector((state) => state.theme.name)].components.Input
      .colorBgContainer};
  padding: 1rem;
  width: 100%;
  font-weight: 400;
`;

const NotHasField = styled(Flex)`
  background-color: #ecf3ff;
  border-left: 6px solid
    ${() => themes[useSelector((state) => state.theme.name)].token.brandColor};
  padding-inline: 15px;
  border-radius: 6px;
  min-height: 56px;
`;

// Showing on the header part of left side
const TItleIconStyle = styled(Typography)`
  min-width: 56px !important;
  width: 56px !important;
  height: 56px;
  border-radius: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) =>
    theme.components.HeaderButtonsStyle.backButtonBg};
  color: ${({ theme }) => theme.components.HeaderButtonsStyle.backButtonClr};
`;

// Table styles
const TableStriped = styled(Table)`
.ant-table {
background-color: ${() =>
  themes[useSelector((state) => state.theme.name)].components.Table.tableBg};
&.table{
  background-color:transparent;
}
.gray-clr {
  color: ${() =>
    themes[useSelector((state) => state.theme.name)].token.grayClr};
}
thead .ant-table-cell {
    background: transparent;
}
.ant-table-cell.ant-table-cell-fix-left{
  background-color: ${() =>
    themes[useSelector((state) => state.theme.name)].components.Table
      .sortedBg} !important;
    }
}
.ant-table-cell {
  border-bottom: 1px solid
      ${() =>
        themes[useSelector((state) => state.theme.name)].components.Table
          .borderColor} !important;
  &.ant-table-column-has-sorters {
    &:hover {
      background-color: ${() =>
        themes[useSelector((state) => state.theme.name)].components.Table
          .sortedBg} !important;
    }
  }
  &.ant-table-column-sort {
    background-color: ${() =>
      themes[useSelector((state) => state.theme.name)].components.Table
        .sortedBg};
  }
}
.imgLocationClr {
  color: ${() =>
    themes[useSelector((state) => state.theme.name)].components.Table
      .imgLocationClr};
  margin-left: 10px;
}
}
  thead>tr:first-child >*:last-child {
    padding-right: 60px;
  }
`;

export {
  DrawerStyle,
  FormStyling,
  InvertedCheckBox,
  NotHasField,
  TItleIconStyle,
  TableStriped,
};
